const inspectionKey = (uuid) => `inspection.${uuid}`;

// Get a single Inspection by UUID.
//
// Note: records are stored in key/value store with the `inspection.` prefix,
// but that should not be provided to this function.
export const getInspection = (db, uuid) => db.get(inspectionKey(uuid));

// Get a list of all inspections
export const getInspections = (db, status = null, user = null) =>
  db
    .keys()
    .then((dbKeys) => dbKeys.filter((key) => key.match(/^inspection\./)))
    .then((inspectionKeys) => inspectionKeys.map((key) => db.get(key)))
    .then((promises) => Promise.all(promises))
    .then((inspections) =>
      inspections
        .filter((i) => !status || i.status === status)
        .filter((i) => !user || i.user.id === user.id)
    );

export const saveInspection = (db, inspection) => {
  if (!inspection.uuid) throw new Error("inspection object is missing a uuid");

  return db.set(inspectionKey(inspection.uuid), inspection);
};

export const deleteInspection = (db, inspection) => {
  if (!inspection.uuid) throw new Error("inspection object is missing a uuid");

  return db.remove(inspectionKey(inspection.uuid));
};

export const saveInspections = (db, inspections) =>
  Promise.all(inspections.map((inspection) => saveInspection(db, inspection)));
