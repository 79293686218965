import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
} from "@ionic/react";
import { checkboxOutline, squareOutline } from "ionicons/icons";
import { displayErrorMessage } from "../appSlice";
import { fetchTemplates } from "./templatesSlice";
import {
  templateSelected,
  templateUnselected,
} from "../inspections/newInspectionSlice";
import TemplateListItem from "./TemplateListItem";

const TemplateSelector = ({ selectedTemplate }) => {
  const dispatch = useDispatch();
  const chooseTemplate = (template) => {
    dispatch(templateSelected(template));
  };
  const changeTemplate = () => {
    dispatch(templateUnselected());
  };
  const templatesStatus = useSelector((state) => state.templates.status);
  const templates = useSelector((state) => state.templates.templates);

  useEffect(() => {
    dispatch(fetchTemplates()).then(
      ({ payload, error, meta: { requestStatus } }) => {
        if (requestStatus === "fulfilled") {
          const fetchedTemplates = payload;
          if (fetchedTemplates.length === 1) {
            dispatch(templateSelected(fetchedTemplates[0]));
          }
        } else if (requestStatus === "rejected") {
          dispatch(displayErrorMessage(error.message));
        }
      }
    );
  }, []);

  const displayTemplates = templates.filter(
    (p) => !selectedTemplate || selectedTemplate.id === p.id
  );

  const renderAllTemplates = displayTemplates.map((template) => (
    <TemplateListItem
      key={template.id}
      template={template}
      onClick={!selectedTemplate && (() => chooseTemplate(template))}
    />
  ));

  if (templatesStatus === "loading") {
    return <IonLoading isOpen />;
  }

  return (
    <IonList>
      <IonListHeader>
        <IonLabel className="checkable">
          <IonIcon icon={selectedTemplate ? checkboxOutline : squareOutline} />
          Step 2: Choose Template
        </IonLabel>
        {selectedTemplate && templates.length > 1 && (
          <IonButton onClick={changeTemplate}>Change</IonButton>
        )}
      </IonListHeader>
      {renderAllTemplates}
    </IonList>
  );
};

export default TemplateSelector;
