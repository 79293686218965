import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonSearchbar,
} from "@ionic/react";
import { checkboxOutline, squareOutline } from "ionicons/icons";
import { fetchProperties } from "./propertiesSlice";
import {
  propertySelected,
  propertyUnselected,
} from "../inspections/newInspectionSlice";
import PropertyListItem from "./PropertyListItem";
import { displayErrorMessage } from "../appSlice";

const PropertySelector = ({ selectedProperty }) => {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const chooseProperty = (property) => {
    dispatch(propertySelected(property));
  };
  const changeProperty = () => {
    dispatch(propertyUnselected());
  };
  const propertiesStatus = useSelector((state) => state.properties.status);
  const properties = useSelector((state) => state.properties.properties);

  useEffect(() => {
    dispatch(fetchProperties()).then(
      ({ payload, error, meta: { requestStatus } }) => {
        if (requestStatus === "fulfilled") {
          const fetchedProperties = payload;
          if (fetchedProperties.length === 1) {
            dispatch(propertySelected(fetchedProperties[0]));
          }
        } else if (requestStatus === "rejected") {
          dispatch(displayErrorMessage(error.message));
        }
      }
    );
  }, []);

  const displayProperties = properties.filter(
    (p) => !selectedProperty || selectedProperty.id === p.id
  );

  const renderAllProperties = displayProperties
    .filter((p) => {
      return query.length
        ? p.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        : true;
    })
    .map((property) => (
      <PropertyListItem
        key={property.id}
        property={property}
        onClick={!selectedProperty && (() => chooseProperty(property))}
      />
    ));

  if (propertiesStatus === "loading") {
    return <IonLoading isOpen />;
  }

  return (
    <IonList className="PropertySelector">
      <IonListHeader>
        <IonLabel className="checkable">
          <IonIcon icon={selectedProperty ? checkboxOutline : squareOutline} />
          Step 1: Choose Property
        </IonLabel>
        {selectedProperty && properties.length > 1 && (
          <IonButton onClick={changeProperty}>Change</IonButton>
        )}
      </IonListHeader>
      {!selectedProperty && (
        <IonSearchbar
          value={query}
          onIonChange={(e) => setQuery(e.target.value)}
          placeholder="Search Properties"
        />
      )}
      {renderAllProperties}
    </IonList>
  );
};

export default PropertySelector;
