import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ServusApi, { cacheDurationMs } from "../../utils/servus_api";

const initialState = {
  templatesByPropertyId: {},
  cachedAtByPropertyId: {},
  templateCount: 0,
  templates: [],
  status: "idle",
};

// A helper to select/initialize state as viewed by a single propertyId
const propertySubstate = (propertyId, globalState) => ({
  templates: globalState.templatesByPropertyId[propertyId] || [],
  cachedAt: globalState.cachedAtByPropertyId[propertyId] || 0,
});

const timeToRefetch = (cachedAt) => Date.now() - cachedAt > cacheDurationMs;

export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (_arg, { dispatch, getState }) => {
    let selectedPropertyId;
    try {
      selectedPropertyId = getState().newInspection.selectedProperty.id;
    } catch {
      selectedPropertyId = null;
    }

    const state = propertySubstate(selectedPropertyId, getState().templates);

    if (state.templates.length > 0 && !timeToRefetch(state.cachedAt)) {
      return state.templates;
    }

    const templates = await ServusApi.getTemplates(selectedPropertyId);

    dispatch({
      type: "templates/templatesLoaded",
      payload: { propertyId: selectedPropertyId, templates },
    });
    return templates;
  }
);

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    templatesLoaded(state, action) {
      const { propertyId, templates } = action.payload;
      const templateCount = templates.length;

      state.templatesByPropertyId[propertyId] = templates;
      state.cachedAtByPropertyId[propertyId] = Date.now();

      state.templateCount = templateCount;
      state.templates = templates;
      if (templateCount === 1) {
        state.currentTemplate = templates[0];
      }
    },
  },
});

export const { templatesLoaded } = templatesSlice.actions;

export default templatesSlice.reducer;
