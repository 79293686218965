import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonImg,
  IonLabel,
  IonList,
  IonRow,
  IonTextarea,
  IonThumbnail,
  IonToggle,
  useIonAlert,
} from "@ionic/react";
import { addCircle, camera, removeCircle, trashOutline } from "ionicons/icons";
import { v1 as uuidv1 } from "uuid";
import PromptWrapper from "./PromptWrapper";
import Modal from "../../../components/Modal";
import Photo from "../../../components/Photo";
import usePhotoGallery from "../../../hooks/usePhotoGallery";
import usePhotoErrorHandler from "../../../hooks/usePhotoErrorHandler";
import {
  addOrUpdatePendingResponsePunchlistItem,
  removePendingResponsePunchlistItem,
  addPunchlistWithNoFindings,
  removePunchlistWithNoFindings,
} from "../localInspectionSlice";
import "./Punchlist.css";

const markerOffset = "10";

const PunchlistMarker = ({ label, location }) => {
  const locationAfterCast = location || {};
  const isPositioned = locationAfterCast.left && locationAfterCast.top;
  const styles = {
    container: {
      width: "27px",
      height: "27px",
      padding: "3px",
    },
    positioned: {
      position: "absolute",
      top: `calc(${locationAfterCast.top || 0}% - ${markerOffset}px)`,
      left: `calc(${locationAfterCast.left || 0}% - ${markerOffset}px)`,
      zIndex: "9999",
    },
    badge: {
      width: "21px",
      height: "21px",
      borderRadius: "99px",
      backgroundColor: "#50b69a",
      textAlign: "center",
      lineHeight: "21px",
      color: "#ffffff",
      fontSize: "0.8rem",
      fontWeight: "bold",
    },
  };

  return (
    <div
      slot={isPositioned ? null : "start"}
      style={isPositioned ? styles.positioned : styles.container}
    >
      <div style={styles.badge}>{label}</div>
    </div>
  );
};

const Punchlist = ({ prompt, viewOnly }) => {
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const response = prompt.response;
  const rawItems = response.rawItems;
  const items = response.items;
  const isNoneFoundInitiallyToggled =
    Array.isArray(rawItems) && items.length === 0;

  // State for item modal open/close
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);

  // State for photo backdrop open/close
  const [isPhotoBackdropOpen, setIsPhotoBackdropOpen] = useState(false);

  const [isNoneFoundToggled, setIsNoneFoundToggled] = useState(
    isNoneFoundInitiallyToggled
  );

  // State for pending item in modal
  const [itemUuid, setItemUuid] = useState(null);
  const [itemText, setItemText] = useState("");
  const [itemPhotos, setItemPhotos] = useState([]);

  const [currentPhoto, setCurrentPhoto] = useState([]);

  const [itemLocation, setItemLocation] = useState({ left: null, top: null });

  const canEdit = !viewOnly;
  const allowDelete = canEdit && itemUuid;
  const inspectionImageMedia = useSelector(
    (state) => state.localInspection.image?.media
  );

  const { takePhoto } = usePhotoGallery();
  const handlePhotoError = usePhotoErrorHandler();

  const takeItemPhoto = () => {
    takePhoto()
      .then((photo) => setItemPhotos([...itemPhotos, photo]))
      .catch((error) => handlePhotoError(error));
  };

  const openItemModal = () => setIsItemModalOpen(true);
  const closeItemModal = () => {
    setIsItemModalOpen(false);
    resetItemModal();
  };
  const resetItemModal = () => {
    setItemUuid(null);
    setItemText("");
    setItemPhotos([]);
    setItemLocation({ left: null, top: null });
  };

  const editItem = (item) => {
    setItemUuid(item.uuid);
    setItemText(item.text);
    setItemPhotos(item.photos);
    setItemLocation(item.location);
    openItemModal();
  };

  const promptToDeleteItem = () => {
    presentAlert({
      header: "Delete this item?",
      message:
        "This punchlist item and its photos will be removed from this inspection. Other punchlist items will not be affected.",
      buttons: ["Cancel", { text: "Delete", handler: deleteItem }],
    });
  };

  const deleteItem = () => {
    dispatch(
      removePendingResponsePunchlistItem({ path: prompt.path, itemUuid })
    );
    closeItemModal();
  };

  const saveItemToPendingResponse = () => {
    const uuid = itemUuid || uuidv1();
    const action = addOrUpdatePendingResponsePunchlistItem({
      path: prompt.path,
      item: {
        uuid,
        text: itemText,
        photos: itemPhotos,
        location: itemLocation,
      },
    });
    dispatch(action);
    closeItemModal();
  };

  const promptRemovePhoto = (idx) => {
    presentAlert({
      header: "Delete this photo?",
      message: "This photo will be deleted. This action cannot be undone.",
      buttons: ["Cancel", { text: "Delete", handler: () => removePhoto(idx) }],
    });
  };

  const removePhoto = (idx) => {
    const nextPhotos = [...itemPhotos];
    nextPhotos.splice(idx, 1);
    setItemPhotos(nextPhotos);
  };

  const openPhotoBackdrop = () => setIsPhotoBackdropOpen(true);

  const closePhotoBackdrop = () => {
    setIsPhotoBackdropOpen(false);
  };

  const viewPhotoBackdrop = (photo) => {
    setCurrentPhoto(photo);
    openPhotoBackdrop();
  };

  const openModalWithTap = (event) => {
    if (isNoneFoundToggled) {
      return;
    }

    event.persist();
    const leftPosition =
      (event.nativeEvent.offsetX / event.target.clientWidth) * 100;
    const topPosition =
      (event.nativeEvent.offsetY / event.target.clientHeight) * 100;

    setItemLocation({ top: topPosition, left: leftPosition });
    openItemModal();
  };

  const openModalWithButton = () => {
    openItemModal();
  };

  const handleImageTap = (event) => {
    if (canEdit) {
      event.persist();

      const leftPosition =
        (event.nativeEvent.offsetX / event.target.clientWidth) * 100;
      const topPosition =
        (event.nativeEvent.offsetY / event.target.clientHeight) * 100;

      setItemLocation({ top: topPosition, left: leftPosition });
    }
  };

  const toggleNoneFound = (event) => {
    const checked = event.detail.checked;
    setIsNoneFoundToggled(checked);

    if (checked) {
      dispatch(addPunchlistWithNoFindings({ path: prompt.path }));
    } else {
      dispatch(removePunchlistWithNoFindings({ path: prompt.path }));
    }
  };

  return (
    <>
      <PromptWrapper
        type="punchlist"
        title={prompt.title}
        description={prompt.description}
        optional={prompt.optional}
      >
        {prompt.data.choose_location && (
          <IonItem lines="none">
            {!inspectionImageMedia && <>(No floorplan or map found)</>}
            {inspectionImageMedia && (
              <div className="floorplan-wrap">
                <IonImg src={inspectionImageMedia} onClick={openModalWithTap} />
                {items.map((item, index) => (
                  <PunchlistMarker
                    key={`marker-${index + 1}`}
                    location={item.location}
                    label={index + 1}
                    onClick={() => editItem(item)}
                  />
                ))}
              </div>
            )}
          </IonItem>
        )}
        {items.length > 0 && (
          <>
            {items.map((item, index) => (
              <IonItem
                className="punchlist-item"
                key={item.uuid}
                detail
                button
                onClick={() => editItem(item)}
              >
                <PunchlistMarker label={index + 1} location={null} />
                <IonLabel>{item.text}</IonLabel>
                {(item.photos || []).length > 0 && (
                  <IonThumbnail slot="end">
                    <Photo photo={item.photos[0]} />
                    <IonBadge color="medium">{item.photos.length}</IonBadge>
                  </IonThumbnail>
                )}
              </IonItem>
            ))}
          </>
        )}
        {canEdit && (
          <>
            {prompt.data.allow_none && items.length === 0 && (
              <IonItem>
                <IonToggle
                  slot="start"
                  checked={isNoneFoundToggled}
                  onIonChange={(e) => toggleNoneFound(e)}
                  disabled={items.length > 0}
                />
                <IonLabel>None found</IonLabel>
              </IonItem>
            )}
            {!isNoneFoundToggled && (
              <IonItem button onClick={openModalWithButton}>
                <IonIcon slot="start" icon={addCircle} color="primary" />
                <IonLabel color="primary">{prompt.data.button_text}</IonLabel>
              </IonItem>
            )}
          </>
        )}
      </PromptWrapper>

      <Modal
        isOpen={isItemModalOpen}
        title="Inspection Note"
        dismissModal={closeItemModal}
        onDidDismiss={closeItemModal}
        footerContent={
          canEdit && (
            <section className="ion-padding">
              <IonButton
                disabled={itemText.length === 0 && itemPhotos.length === 0}
                expand="block"
                fill="solid"
                color="primary"
                onClick={saveItemToPendingResponse}
              >
                Save Note
              </IonButton>
            </section>
          )
        }
        buttonsEnd={
          allowDelete && (
            <IonButton onClick={promptToDeleteItem}>
              <IonIcon icon={trashOutline} color="danger" />
            </IonButton>
          )
        }
      >
        <IonList>
          <IonItemDivider mode="md" color="light">
            Describe the concern - what and where
          </IonItemDivider>
          <IonItem lines="none">
            <IonTextarea
              rows={5}
              placeholder="Enter your note here..."
              value={itemText}
              onIonChange={(e) => setItemText(e.detail.value)}
              disabled={!canEdit}
            />
          </IonItem>

          <IonItemDivider mode="md" color="light">
            Photos
          </IonItemDivider>
          {itemPhotos.length > 0 && (
            <IonItem lines="none" className="punchlist-item-photos">
              <IonGrid>
                <IonRow>
                  {itemPhotos.map((photo, index) => (
                    <IonCol size="6" key={photo.fileName}>
                      <Photo
                        alt={photo.fileName}
                        photo={photo}
                        onClick={() => viewPhotoBackdrop(photo)}
                      />
                      {canEdit && (
                        <IonIcon
                          className="remove-photo"
                          size="large"
                          icon={removeCircle}
                          color="danger"
                          onClick={() => promptRemovePhoto(index)}
                        />
                      )}
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
          {canEdit && (
            <IonItem lines="none">
              <IonButton fill="clear" color="primary" onClick={takeItemPhoto}>
                <IonIcon icon={camera} />
                &nbsp; Add Photo
              </IonButton>
            </IonItem>
          )}
          {prompt.data.choose_location && inspectionImageMedia && (
            <div>
              <IonItemDivider mode="md" color="light">
                Location
              </IonItemDivider>
              <IonItem className="ion-padding punchlist-item-location">
                <div className="floorplan-wrap">
                  <PunchlistMarker label={null} location={itemLocation} />
                  <IonImg src={inspectionImageMedia} onClick={handleImageTap} />
                </div>
              </IonItem>
            </div>
          )}
        </IonList>

        <Modal
          isOpen={isPhotoBackdropOpen}
          title="Image"
          canDismiss
          dismissModal={closePhotoBackdrop}
          onDidDismiss={closePhotoBackdrop}
        >
          <section className="ion-padding">
            <Photo photo={currentPhoto} />
          </section>
        </Modal>
      </Modal>
    </>
  );
};

export default Punchlist;
