import React from "react";
import { useSelector } from "react-redux";
import { IonLoading } from "@ionic/react";

const AppLoading = () => {
  const appIsLoading = useSelector((state) => !!state.app.isLoading);

  return <IonLoading isOpen={appIsLoading} />;
};

export default AppLoading;
