import { v4 as uuidv4 } from "uuid";
import InspectionPrompt from "./InspectionPrompt.model";
import InspectionResponse from "./InspectionResponse.model";
import InspectionStats from "./InspectionStats";

export default class InspectionSection {
  promptCount = 0;

  answerCount = 0;

  requiredPromptCount = 0;

  requiredAnswerCount = 0;

  constructor(params = {}) {
    this.uuid = params.uuid || uuidv4();
    this.title = params.title;
    this.description = params.description;
    this.optional = !!params.optional;
    this.meta = params.meta || {};
    this.prompts = this.#castPrompts(params.prompts || []);
    this.response = InspectionSection.#castResponse(params.response);

    Object.assign(this, new InspectionStats(this.prompts).compute());
  }

  get disabled() {
    return this.response ? this.response.skipped : this.initialState === "off";
  }

  get enabled() {
    return !this.disabled;
  }

  get complete() {
    return this.requiredPromptCount === this.requiredAnswerCount;
  }

  get initialState() {
    return this.meta.initial_state === "off" ? "off" : "on";
  }

  #castPrompts(promptsData) {
    const sectionUuid = this.uuid;

    return promptsData
      .map((data) =>
        data.sectionUuid === this.uuid ? data : { ...data, sectionUuid }
      )
      .map((data) => InspectionPrompt.castPrompt(data));
  }

  static #castResponse(responseParams) {
    if (!responseParams) {
      return null;
    }

    return new InspectionResponse(responseParams);
  }
}
