import React from "react";
import { IonText } from "@ionic/react";

const Unknown = ({ prompt }) => {
  return (
    <div className="prompt-type-unknown">
      <IonText color="danger">Prompt Type Unknown: {prompt.type}</IonText>
    </div>
  );
};

export default Unknown;
