function base64url(string) {
  return btoa(string).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}

function ascii(octets) {
  return octets.reduce(
    (string, char) => string.concat(String.fromCharCode(char)),
    ""
  );
}

async function sha256(string) {
  const digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(string)
  );

  return new Uint8Array(digest);
}

export const generateCodeVerifier = () => {
  const octets = crypto.getRandomValues(new Uint8Array(32));
  return base64url(ascii(octets));
};

export const computeCodeChallenge = async (codeVerifier) => {
  const digest = await sha256(codeVerifier);
  return base64url(ascii(digest));
};
