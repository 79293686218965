import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_SERVUS_URL;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 100000;

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default {
  get: axios.get,
  post: axios.post,
};
