import React, { useState } from "react";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { checkmarkCircle, syncCircle } from "ionicons/icons";
import Photo from "./Photo";

export interface IProps {
  keys: string[];
  uploadedKeys: string[];
  currentUploadKey?: string;
}

const PhotoGrid = ({ keys, uploadedKeys, currentUploadKey }: IProps) => {
  const [numPages, setNumPages] = useState<number>(1);

  const PAGE_SIZE = 12;
  const numPhotosLoaded = numPages * PAGE_SIZE;
  const morePhotos = numPhotosLoaded < keys.length;

  const isKeyUploaded = (key: string) => uploadedKeys.includes(key);
  const isKeyUploading = (key: string) => currentUploadKey === key;
  const icon = (key: string) =>
    isKeyUploaded(key) ? checkmarkCircle : syncCircle;
  const iconColor = (key: string) =>
    isKeyUploaded(key) || isKeyUploading(key) ? "uploaded" : "medium";

  return (
    <>
      <IonGrid className="photo-grid">
        <IonRow>
          {keys.slice(0, numPhotosLoaded).map((photoKey) => (
            <IonCol key={photoKey} size="4">
              <div className="photo-with-status">
                <Photo photo={{ uuid: photoKey }} alt={photoKey} />
                <IonIcon icon={icon(photoKey)} color={iconColor(photoKey)} />
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      {morePhotos && (
        <IonButton
          fill="outline"
          expand="block"
          onClick={() => setNumPages(numPages + 1)}
        >
          Load More
        </IonButton>
      )}
    </>
  );
};

export default PhotoGrid;
