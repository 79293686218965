import { useDispatch } from "react-redux";
import { displayFatalErrorMessage } from "../features/appSlice";
import useLogger from "./useLogger";
import {
  FATAL_DB_ERRORS,
  DATA_STORAGE_FAILURE_ERROR,
} from "../utils/errorHandling";

const IGNORABLE_ERRORS = ["User cancelled photos app"];

export default function usePhotoErrorHandler() {
  const dispatch = useDispatch();
  const logger = useLogger();

  const isIgnorableError = (error) =>
    IGNORABLE_ERRORS.some((message) => error.message.startsWith(message));
  const isFatalError = (error) =>
    FATAL_DB_ERRORS.some((message) => error.message.startsWith(message));

  const handlePhotoError = (error) => {
    if (isIgnorableError(error)) {
      return;
    }

    logger.error(error);

    if (isFatalError(error)) {
      dispatch(displayFatalErrorMessage(DATA_STORAGE_FAILURE_ERROR));
    }
  };

  return handlePhotoError;
}
