import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonBadge,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonTextarea,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonModal,
} from "@ionic/react";
import { cameraOutline } from "ionicons/icons";
import PromptWrapper from "./PromptWrapper";
import Photo from "../../../components/Photo";
import "./Textarea.css";
import usePhotoGallery from "../../../hooks/usePhotoGallery";
import usePhotoErrorHandler from "../../../hooks/usePhotoErrorHandler";
import { saveResponsePartial } from "../localInspectionSlice";

const Textarea = ({ prompt }) => {
  const dispatch = useDispatch();
  const [modalPhotos, setModalPhotos] = useState([]);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const { takePhoto } = usePhotoGallery();
  const handlePhotoError = usePhotoErrorHandler();
  const response = prompt.response;

  const dismissPhotoModal = () => setShowPhotoModal(false);

  const setInResponse = (key, value) => {
    dispatch(
      saveResponsePartial({ path: prompt.path, type: "textarea", key, value })
    );
  };

  const openPhotoModal = () => {
    setModalPhotos(response.photos || []);
    setShowPhotoModal(true);
  };

  const savePhotos = () => {
    setInResponse("photos", modalPhotos);
    dismissPhotoModal();
    setModalPhotos([]);
  };

  const takeModalPhoto = () => {
    takePhoto()
      .then((photo) =>
        setModalPhotos((currentPhotos) => [photo, ...currentPhotos])
      )
      .catch((error) => handlePhotoError(error));
  };

  return (
    <PromptWrapper
      type="textarea"
      title={prompt.title}
      description={prompt.description}
      optional={prompt.optional}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="10">
            <IonTextarea
              value={response.value}
              name={prompt.uuid}
              onIonChange={(e) => setInResponse("value", e.detail.value)}
            />
          </IonCol>
          <IonCol size="2" className="last">
            <div className="control-icon control-icon-camera">
              <IonIcon
                style={{ fontSize: "32px" }}
                icon={cameraOutline}
                onClick={openPhotoModal}
              />
              {response.photos.length > 0 ? (
                <IonBadge color="success">{response.photos.length}</IonBadge>
              ) : (
                <IonBadge color="medium">0</IonBadge>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showPhotoModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={dismissPhotoModal}>Close</IonButton>
            </IonButtons>
            <IonTitle>Photos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              {modalPhotos.map((photo) => (
                <IonCol size="6" key={photo.fileName}>
                  <Photo photo={photo} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          <IonButton fill="outline" onClick={takeModalPhoto}>
            <IonIcon slot="start" icon={cameraOutline} />
            Add Photo
          </IonButton>
          <IonButton expand="block" onClick={savePhotos}>
            Save
          </IonButton>
        </IonContent>
      </IonModal>
    </PromptWrapper>
  );
};

export default Textarea;
