import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import * as Property from "../../models/property";

const PropertyListItem = ({ property, onClick }) => (
  <IonItem button={!!onClick} onClick={onClick}>
    <IonLabel>
      <h2>{property.name}</h2>
      <p>{Property.displayAddress(property)}</p>
    </IonLabel>
  </IonItem>
);

export default PropertyListItem;
