// These redux patterns follow "Modern Redux with Redux Toolkit"
// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./features/appSlice";
import submittedInspectionsReducer from "./features/inspections/submittedInspectionsSlice";
import inspectionsReducer from "./features/inspections/inspectionsSlice";
import newInspectionReducer from "./features/inspections/newInspectionSlice";
import localInspectionReducer from "./features/inspections/localInspectionSlice";
import propertiesReducer from "./features/properties/propertiesSlice";
import templatesReducer from "./features/templates/templatesSlice";
import unitsReducer from "./features/units/unitsSlice";
import userReducer from "./features/users/userSlice";
import photoUploaderReducer from "./features/photos/photoUploaderSlice";
import { servusApi } from "./utils/servus_api_rtk";

const store = configureStore({
  reducer: {
    app: appReducer,
    submittedInspections: submittedInspectionsReducer,
    inspections: inspectionsReducer,
    newInspection: newInspectionReducer,
    localInspection: localInspectionReducer,
    properties: propertiesReducer,
    templates: templatesReducer,
    units: unitsReducer,
    user: userReducer,
    photoUploader: photoUploaderReducer,
    [servusApi.reducerPath]: servusApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(servusApi.middleware),
});

export default store;
