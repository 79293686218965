import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import {
  enumeratePendingUploads,
  performUpload,
} from "../features/photos/photoUploaderSlice";
import useDatabase from "../hooks/useDatabase";
import "./PhotoProgress.css";

const PhotoProgress = () => {
  const db = useDatabase();
  const dispatch = useDispatch();
  const photoUploaderState = useSelector((state) => state.photoUploader);

  const [dismissed, setDismissed] = useState(false);

  // Check for uploads if the uploader state changes
  useEffect(() => {
    dispatch(enumeratePendingUploads({ db }));

    // If the uploader becomes active (or errors), allow displaying the
    // progress bar again.
    if (photoUploaderState.status !== "idle") {
      setDismissed(false);
    }
  }, [photoUploaderState.status]);

  const numPhotosToUpload = photoUploaderState.pendingUploadKeys.length;

  const UploadCount = () => {
    return (
      <span>{`(${
        photoUploaderState.currentUploadIndex + 1
      } of ${numPhotosToUpload})`}</span>
    );
  };

  // Display the popover if actively uploading, or if there are pending uploads and the uploader is idle
  const shouldDisplay =
    photoUploaderState.status === "uploading" ||
    (photoUploaderState.status === "idle" && numPhotosToUpload > 0);

  if (dismissed) return;

  return (
    <>
      {shouldDisplay && (
        <IonCard className="photo-progress">
          <IonGrid>
            <IonRow className="photo-progress-row">
              <IonCol>
                <strong>Uploading Photos</strong>{" "}
                {numPhotosToUpload > 0 &&
                  photoUploaderState.status !== "idle" && <UploadCount />}
                {numPhotosToUpload > 0 &&
                  photoUploaderState.status === "idle" && <span>(Paused)</span>}
              </IonCol>
              {numPhotosToUpload > 0 &&
                photoUploaderState.status === "idle" && (
                  <IonCol size={3}>
                    <IonButton
                      size="small"
                      fill="clear"
                      onClick={() => dispatch(performUpload({ db }))}
                      className="photo-progress-resume"
                    >
                      Resume
                    </IonButton>
                  </IonCol>
                )}
              <IonCol size="2">
                <IonButton
                  size="small"
                  fill="clear"
                  onClick={() => setDismissed(true)}
                  className="photo-progress-hide"
                >
                  Hide
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="photo-progress-row">
              <IonCol>
                <IonProgressBar
                  value={
                    photoUploaderState.currentUploadIndex / numPhotosToUpload
                  }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      )}
    </>
  );
};

export default PhotoProgress;
