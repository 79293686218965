export default class InspectionStats {
  promptCount = 0;

  answerCount = 0;

  requiredPromptCount = 0;

  requiredAnswerCount = 0;

  #prompts = [];

  #ignoredSectionUuids = [];

  constructor(prompts, ignoredSectionUuids = []) {
    this.#prompts = prompts;
    this.#ignoredSectionUuids = ignoredSectionUuids;
  }

  compute() {
    this.#countEachPrompt(this.#prompts);

    return {
      promptCount: this.promptCount,
      answerCount: this.answerCount,
      requiredPromptCount: this.requiredPromptCount,
      requiredAnswerCount: this.requiredAnswerCount,
    };
  }

  #counterFn = (prompt) => {
    if (prompt.type !== "group" && prompt.type !== "repeater") {
      this.promptCount += 1;

      if (prompt.response?.answered) {
        this.answerCount += 1;
      }

      if (prompt.required) {
        this.requiredPromptCount += 1;
        if (prompt.response?.answered) {
          this.requiredAnswerCount += 1;
        }
      }
    }
  };

  #skipFn = (prompt) => {
    const ignoreSections =
      prompt.sectionUuid &&
      this.#ignoredSectionUuids.includes(prompt.sectionUuid);

    if (ignoreSections || !prompt.shouldDisplay) {
      return true;
    } else {
      return false;
    }
  };

  #countEachPrompt(prompts) {
    prompts.forEach((prompt) => {
      if (this.#skipFn(prompt)) {
        return;
      }

      this.#counterFn(prompt);
      this.#countEachPrompt(prompt.prompts || []);
    });
  }
}
