import Bugsnag from "@bugsnag/js";
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  isRejectedWithValue,
  miniSerializeError,
} from "@reduxjs/toolkit";
import * as Cache from "../../utils/local_cache";
import ServusApi from "../../utils/servus_api";
import InspectionModel from "../../models/Inspection.model";
import { setFresh as setDraftInspectionFresh } from "./localInspectionSlice";
import { performUpload as startPhotoUpload } from "../photos/photoUploaderSlice";
import { displayFatalErrorMessage } from "../appSlice";
import {
  FATAL_DB_ERRORS,
  DATA_STORAGE_FAILURE_ERROR,
} from "../../utils/errorHandling";
import useLogger from "../../hooks/useLogger";

const logger = useLogger();

const inspectionsAdapter = createEntityAdapter({
  selectId: (inspection) => inspection.uuid,
  sortComparer: (a, b) => a.unit.name.localeCompare(b.unit.name),
});

const initialState = inspectionsAdapter.getInitialState({
  status: "idle", // idle | loading | error | saving
  error: null,
});

export const fetchInspections = createAsyncThunk(
  "inspections/fetchInspections",
  async ({ db, user }) => {
    const inspections = await Cache.getInspections(db, null, user);
    return inspections;
  }
);

export const saveInspection = createAsyncThunk(
  "inspections/saveInspection",
  async (
    { db, inspection: rawInspectionData },
    { dispatch, rejectWithValue }
  ) => {
    // Massage data from the raw form submission
    const inspectionModel = new InspectionModel(rawInspectionData);
    const persistableInspectionData = inspectionModel.massagedData();

    // Always save as draft first, in case the network fails
    try {
      await Cache.saveInspection(db, {
        ...persistableInspectionData,
        status: "draft",
      });
    } catch (error) {
      if (
        FATAL_DB_ERRORS.some((message) => error.message.startsWith(message))
      ) {
        dispatch(displayFatalErrorMessage(DATA_STORAGE_FAILURE_ERROR));

        /**
         * Since the default "rejected" case will display a generic error, but
         * in this case we have already triggered the fatal error handling instead,
         * we reject with a value that includes the error in the payload so that
         * the rejected case can handle things in a special way.
         */
        return rejectWithValue({ error: miniSerializeError(error) });
      }
      throw error;
    }

    if (persistableInspectionData.status === "submitted") {
      const submittableInspectionData = await inspectionModel.massagedData();
      await ServusApi.postInspection({ inspection: submittableInspectionData });
      await Cache.saveInspection(db, persistableInspectionData);
    }
    // Begin the upload of the photos in the background
    dispatch(startPhotoUpload({ db }));

    // Ensure the draft version of this inspection is set fresh, since a dirty inspection
    // indicates that it still needs to be saved
    dispatch(setDraftInspectionFresh());
    return persistableInspectionData;
  }
);

export const deleteInspection = createAsyncThunk(
  "inspections/deleteInspection",
  async ({ db, inspection }) => {
    await Cache.deleteInspection(db, inspection);
    return inspection;
  }
);

const inspectionsSlice = createSlice({
  name: "inspections",
  initialState,
  reducers: {
    inspectionAdded: inspectionsAdapter.addOne,
    inspectionsAdded: inspectionsAdapter.addMany,
    inspectionUpdated: inspectionsAdapter.updateOne,
    inspectionRemoved: inspectionsAdapter.removeOne,
    reset(state) {
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchInspections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspections.fulfilled, (state, action) => {
        inspectionsAdapter.setAll(state, action.payload);
        state.status = "idle";
      })
      .addCase(fetchInspections.rejected, (state) => {
        state.status = "error";
      })
      .addCase(saveInspection.pending, (state) => {
        state.status = "saving";
        state.error = null;
      })
      .addCase(saveInspection.fulfilled, (state, action) => {
        const inspection = action.payload;
        state.status = "idle";
        inspectionsAdapter.updateOne(state, {
          id: inspection.uuid,
          changes: inspection,
        });
      })
      .addCase(saveInspection.rejected, (state, action) => {
        if (isRejectedWithValue(action) && action.payload?.error) {
          Bugsnag.notify(action.payload.error);
          logger.error(action.payload.error); // eslint-disable-line
        } else {
          Bugsnag.notify(action.error);
          logger.error(action.error); // eslint-disable-line
          state.status = "error";
          state.error =
            "There was an error saving your inspection. Please connect to stable Wi-Fi and try again";
        }
      })
      .addCase(deleteInspection.fulfilled, (state, action) => {
        const inspection = action.payload;
        inspectionsAdapter.removeOne(state, inspection.uuid);
      });
  },
});

export const { inspectionsAdded, inspectionUpdated, inspectionRemoved, reset } =
  inspectionsSlice.actions;

export default inspectionsSlice.reducer;

export const {
  selectAll: selectInspections,
  selectById: selectInspectionById,
} = inspectionsAdapter.getSelectors((state) => state.inspections);

export const selectDraftInspections = createSelector(
  selectInspections,
  (inspections) =>
    inspections.filter((inspection) => inspection.status === "draft")
);

export const selectSubmittedInspections = createSelector(
  selectInspections,
  (inspections) =>
    inspections
      .filter((inspection) => inspection.status === "submitted")
      .sort((a, b) => b.submitted_at.localeCompare(a.submitted_at))
);
