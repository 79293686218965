import React, { useEffect } from "react";
import hasOwn from "object.hasown";
import Unknown from "./prompts/Unknown";
import { useDispatch, useSelector } from "react-redux";
import { removePendingResponse } from "./localInspectionSlice";

const promptTypes = {};

export const registerPromptType = (key, Component) => {
  promptTypes[key] = Component;
};

const Prompt = ({ prompt }) => {
  const C = promptTypes[prompt.type] || Unknown;
  const dispatch = useDispatch();
  const pendingResponses = useSelector(
    (state) => state.localInspection.pendingResponses
  );

  useEffect(() => {
    // If a prompt is removed from view, we must clear any pending responses
    // it has added to the redux state.
    if (prompt.shouldDisplay === false) {
      // First, delete subprompts
      if (prompt.prompts.length) {
        prompt.prompts[0]?.prompts.forEach((prompt) => {
          try {
            if (hasOwn(pendingResponses, prompt.path)) {
              dispatch(
                removePendingResponse({
                  path: prompt.path,
                })
              );
            }
          } catch (e) {
            return;
          }
        });
      }

      // Then delete the prompt response
      try {
        if (hasOwn(pendingResponses, prompt.path)) {
          dispatch(
            removePendingResponse({
              path: prompt.path,
            })
          );
        }
      } catch (e) {
        return;
      }
    }
  }, [prompt]);

  if (prompt.shouldDisplay) {
    return <C prompt={prompt} />;
  } else {
    return <></>;
  }
};

export default Prompt;
