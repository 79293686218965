import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IonButton } from "@ionic/react";
import { addRepeater } from "../localInspectionSlice";
import PromptWrapper from "./PromptWrapper";
import Repeater from "./Repeater";
import "./Group.css";
import renderMarkdownToHTML from "../../../utils/renderMarkdownToHTML";

const Group = ({ prompt, viewOnly }) => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const repeaterKeys = prompt?.response?.repeaterKeys || [];

  useEffect(() => {
    if (!ready) {
      if (repeaterKeys.length === 0) {
        dispatch(addRepeater({ path: prompt.path }));
      } else {
        setReady(true);
      }
    }
  }, [ready, repeaterKeys]);

  const onAddRepeater = () => {
    dispatch(addRepeater({ path: prompt.path }));
  };

  return (
    <PromptWrapper type="group">
      <section className="prompt-type-group__inner">
        {prompt.title && <h3 className="prompt-title">{prompt.title}</h3>}
        {prompt.description && (
          <div
            className="description"
            dangerouslySetInnerHTML={renderMarkdownToHTML(prompt.description)}
          />
        )}

        {ready &&
          prompt.prompts.map((repeaterPrompt, index) => (
            <Repeater
              key={repeaterPrompt.path}
              prompt={repeaterPrompt}
              groupPrompt={prompt}
              viewOnly={viewOnly}
              removable={index > 0}
            />
          ))}

        {prompt.data.repeatable && (
          <div>
            <IonButton
              fill="outline"
              color="medium"
              expand="block"
              onClick={onAddRepeater}
            >
              Add Another
            </IonButton>
          </div>
        )}
      </section>
    </PromptWrapper>
  );
};

export default Group;
