import api from "./api";

const cacheDurationSeconds = parseInt(
  process.env.REACT_APP_API_CACHE_DURATION_SECONDS || 0,
  10
);
export const cacheDurationMs = cacheDurationSeconds * 1000;

const extractDataFromKey = (key) => (data) => JSON.parse(data)[key];

const returningData = (apiCall) => apiCall.then((response) => response.data);

const ServusApi = {
  getProperties: () =>
    returningData(
      api.get("/api/properties", {
        params: { having_inspection_templates: "1", limit: 1000 },
        transformResponse: extractDataFromKey("properties"),
      })
    ),
  getUnits: (propertyId) =>
    returningData(
      api.get("/api/units", {
        params: {
          property_id: propertyId,
          sort: "name",
          limit: 2000,
          omit_residents: true,
        },
        transformResponse: extractDataFromKey("units"),
      })
    ),
  getUnitImage: (unitId, role) =>
    returningData(
      api.get(`/api/units/${unitId}/image/`, {
        params: { role, encoding: "base64" },
        transformResponse: extractDataFromKey("unit_image"),
      })
    ),
  getTemplates: (propertyId) =>
    returningData(
      api.get("/api/inspection_templates", {
        params: { property_id: propertyId },
        transformResponse: extractDataFromKey("inspection_templates"),
      })
    ),
  postInspection: (inspectionData) =>
    returningData(api.post("/api/inspections", inspectionData)),
};

export default ServusApi;
