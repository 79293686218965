import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonTitle,
  IonTextarea,
} from "@ionic/react";
import {
  cameraOutline,
  documentTextOutline,
  checkmarkSharp,
} from "ionicons/icons";
import PromptWrapper from "./PromptWrapper";
import "./Select.css";
import Photo from "../../../components/Photo";
import usePhotoGallery from "../../../hooks/usePhotoGallery";
import usePhotoErrorHandler from "../../../hooks/usePhotoErrorHandler";
import { saveResponsePartial } from "../localInspectionSlice";

const Select = ({ prompt }) => {
  const dispatch = useDispatch();
  const [modalPhotos, setModalPhotos] = useState([]);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const { takePhoto } = usePhotoGallery();
  const handlePhotoError = usePhotoErrorHandler();

  const dismissPhotoModal = () => setShowPhotoModal(false);
  const dismissNoteModal = () => setShowNoteModal(false);

  const response = prompt.response;

  const setInResponse = (key, value) => {
    dispatch(
      saveResponsePartial({ path: prompt.path, type: "picklist", key, value })
    );
  };

  const handleSelectChange = (value) => {
    setInResponse("value", value);
  };

  const openPhotoModal = () => {
    setModalPhotos(response.photos || []);
    setShowPhotoModal(true);
  };

  const openNoteModal = () => {
    setShowNoteModal(true);
  };

  const savePhotos = () => {
    setInResponse("photos", modalPhotos);
    dismissPhotoModal();
    setModalPhotos([]);
  };

  const saveNote = () => {
    dismissNoteModal();
  };

  const handleNoteChange = (value) => {
    setInResponse("note", value);
  };

  const takeModalPhoto = () => {
    takePhoto()
      .then((photo) =>
        setModalPhotos((currentPhotos) => [photo, ...currentPhotos])
      )
      .catch((error) => handlePhotoError(error));
  };

  const currentColor = prompt.data.choices.find(
    (choice) => choice.value === response.value
  )?.color;

  return (
    <PromptWrapper
      type="select"
      title={prompt.title}
      description={prompt.description}
      optional={prompt.optional}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="10">
            <IonItem color="transparent">
              <IonSelect
                value={response.value}
                name={prompt.uuid}
                onIonChange={(e) => handleSelectChange(e.detail.value)}
                multiple={prompt.data.multiple === true}
                placeholder="Please choose..."
                class={`selected-option-color-${currentColor}`}
              >
                {prompt.data.choices.map((choice, index) => (
                  <IonSelectOption
                    key={choice.value ? choice.value : index}
                    value={choice.value}
                    class={`select-option-color-${choice.color}`}
                  >
                    {choice.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="2" className="last">
            <div className="control-icon control-icon-camera">
              <IonIcon
                style={{ fontSize: "32px" }}
                icon={cameraOutline}
                onClick={openPhotoModal}
              />
              {response.photos.length > 0 ? (
                <IonBadge color="success">{response.photos.length}</IonBadge>
              ) : (
                <IonBadge color="medium">0</IonBadge>
              )}
            </div>
            <div className="control-icon">
              <IonIcon
                style={{ fontSize: "32px" }}
                icon={documentTextOutline}
                onClick={() => openNoteModal()}
              />
              {response.note && (
                <IonBadge color="success">
                  <IonIcon icon={checkmarkSharp} />
                </IonBadge>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showPhotoModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={dismissPhotoModal}>Close</IonButton>
            </IonButtons>
            <IonTitle>Photos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              {modalPhotos.map((photo) => (
                <IonCol size="6" key={photo.fileName}>
                  <Photo photo={photo} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          <IonButton fill="outline" onClick={takeModalPhoto}>
            <IonIcon slot="start" icon={cameraOutline} />
            Add Photo
          </IonButton>
          <IonButton expand="block" onClick={savePhotos}>
            Save
          </IonButton>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showNoteModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={dismissNoteModal}>Close</IonButton>
            </IonButtons>
            <IonTitle>Note</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonTextarea
            rows="5"
            placeholder="Enter your note..."
            value={response.note}
            onIonChange={(e) => handleNoteChange(e.detail.value)}
          />
          <IonButton expand="block" onClick={saveNote}>
            Save
          </IonButton>
        </IonContent>
      </IonModal>
    </PromptWrapper>
  );
};

export default Select;
