import { createSlice } from "@reduxjs/toolkit";
import InspectionResponseMigrator from "../../models/InspectionResponseMigrator.model";

const initialState = {
  status: "fresh", // 'fresh' | 'dirty'
  pendingResponses: {},
  uuid: null,
  image: {},
};

// The "local inspection" is the one being edited, before it is persisted back to the database.
// A better name is probably "current inspection".
const localInspectionSlice = createSlice({
  name: "localInspection",
  initialState,
  reducers: {
    inspectionLoaded(state, action) {
      const inspection = action.payload;
      state.uuid = inspection.uuid;
      state.image = inspection.image;
      state.pendingResponses =
        inspection.schema_version === "2"
          ? inspection.responses
          : InspectionResponseMigrator.migrate(inspection.responses);
      state.status = "fresh";
    },
    inspectionUnloaded(state) {
      Object.assign(state, initialState);
    },
    addOrUpdatePendingResponsePunchlistItem(state, { payload }) {
      const { path, item } = payload;
      const pendingResponse = state.pendingResponses[path] || {};
      const currentItems = pendingResponse.items || [];
      const itemIndex = currentItems.findIndex((el) => el.uuid === item.uuid);
      const nextItems = [...currentItems];

      if (itemIndex > -1) {
        nextItems.splice(itemIndex, 1, item);
      } else {
        nextItems.push(item);
      }

      state.pendingResponses[path] = {
        type: "punchlist",
        items: nextItems,
      };
      state.status = "dirty";
    },
    addPunchlistWithNoFindings(state, { payload }) {
      const { path } = payload;

      state.pendingResponses[path] = {
        type: "punchlist",
        items: [],
      };
      state.status = "dirty";
    },
    removePunchlistWithNoFindings(state, { payload }) {
      const { path } = payload;

      delete state.pendingResponses[path];
    },
    updateSectionSkipped(state, { payload }) {
      const { skipped, sectionUuid } = payload;
      if (skipped) {
        state.pendingResponses[sectionUuid] = { skipped: true };
      } else {
        state.pendingResponses[sectionUuid] = { skipped: false };
      }
      state.status = "dirty";
    },
    addRepeater(state, { payload: { path } }) {
      state.pendingResponses[path] ||= {};
      state.pendingResponses[path].type = "group";
      state.pendingResponses[path].value ||= [];
      const repeaterKeys = state.pendingResponses[path].value || [];
      const nextRepeaterKey =
        repeaterKeys.length > 0
          ? (Math.max(...repeaterKeys) + 1).toString()
          : "0";
      const nextRepeaterKeys = [...repeaterKeys, nextRepeaterKey];

      state.pendingResponses[path].value = nextRepeaterKeys;
      state.status = "dirty";
    },
    removeRepeater(state, { payload }) {
      const { path, repeaterKey } = payload;

      state.pendingResponses[path] ||= {};

      if (state.pendingResponses[path].type === "group") {
        const repeaterKeys = state.pendingResponses[path].value || [];

        const nextRepeaterKeys = repeaterKeys.filter(
          (key) => key !== repeaterKey
        );

        state.pendingResponses[path].value = nextRepeaterKeys;

        state.pendingResponses[path].labels ||= {};
        delete state.pendingResponses[path].labels[repeaterKey];

        const groupUuid = path.split("/").at(-1);
        const repeaterPath = `${path}/${groupUuid}[${repeaterKey}]`;

        Object.keys(state.pendingResponses).forEach((responsePath) => {
          if (responsePath.startsWith(repeaterPath)) {
            delete state.pendingResponses[responsePath];
          }
        });
        state.status = "dirty";
      }
    },
    updateGroupLabel(state, { payload }) {
      const { path, value } = payload;
      state.pendingResponses[path] ||= {};
      state.pendingResponses[path].type = "repeater";
      state.pendingResponses[path].value = value;
      state.status = "dirty";
    },
    removePendingResponse(state, { payload }) {
      const { path } = payload;

      delete state.pendingResponses[path];

      state.status = "dirty";
    },
    removePendingResponsePunchlistItem(state, { payload }) {
      const { path, itemUuid } = payload;

      const pendingResponse = state.pendingResponses[path] || {};
      const currentItems = pendingResponse.items || [];
      const nextItems = [...currentItems];

      const itemIndex = currentItems.findIndex((el) => el.uuid === itemUuid);

      if (itemIndex > -1) {
        nextItems.splice(itemIndex, 1);
      }

      state.pendingResponses[path].items = nextItems;
      state.status = "dirty";
    },
    saveResponse(state, { payload }) {
      const { path, type, responseData } = payload;

      if (!state.pendingResponses[path]) {
        state.pendingResponses[path] = {};
      }
      state.pendingResponses[path] = { type, ...responseData };
      state.status = "dirty";
    },
    saveResponsePartial(state, { payload }) {
      const { path, type, key, value } = payload;

      if (!state.pendingResponses[path]) {
        state.pendingResponses[path] = {};
      }
      state.pendingResponses[path].type = type;
      state.pendingResponses[path][key] = value;
      state.status = "dirty";
    },
    setFresh(state) {
      state.status = "fresh";
    },
  },
});

export const {
  inspectionLoaded,
  inspectionUnloaded,
  addOrUpdatePendingResponsePunchlistItem,
  removePendingResponse,
  removePendingResponsePunchlistItem,
  saveResponse,
  saveResponsePartial,
  addPunchlistWithNoFindings,
  removePunchlistWithNoFindings,
  addRepeater,
  removeRepeater,
  updateGroupLabel,
  updateSectionSkipped,
  setFresh,
} = localInspectionSlice.actions;

export const selectPendingResponse = (path) => (state) =>
  selectPendingResponses(state)[path] || {};

export const selectPendingResponses = (state) =>
  state.localInspection.pendingResponses || {};

export default localInspectionSlice.reducer;
