export const getJsonApiRelationship = (document, relationship) => {
  if (!document) {
    return null;
  }

  const { type: relType, id: relId } =
    document?.data?.relationships[relationship]?.data;
  if (!relId) {
    return null;
  }

  const included = document.included || [];
  return included.find(
    (include) => include.type === relType && include.id === relId
  );
};
