import React from "react";
import HelpPage from "../components/HelpPage";

const AboutOfflineMode = () => {
  return (
    <HelpPage>
      <h1>About Offline Mode</h1>

      <p>
        Once you start an inspection draft, you can continue your work&nbsp;
        <strong>without</strong>
        &nbsp;access to the internet!
      </p>

      <p>You need internet access for the following:</p>

      <ul>
        <li>Starting a new inspection draft</li>
        <li>Submitting an inspection</li>
      </ul>

      <p>
        While you&apos;re working, all of your photos and entries are saved
        safely to your device.
      </p>
    </HelpPage>
  );
};

export default AboutOfflineMode;
