import React, { useRef, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPopover,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { isPresent } from "../../../utils/helpers";
import "./Punchlist.css";
import renderMarkdownToHTML from "../../../utils/renderMarkdownToHTML";

const PromptWrapper = ({
  children,
  description,
  menuItems = [],
  optional = false,
  title,
  type,
}) => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const hasMenu = Array.isArray(menuItems) && menuItems.length > 0;

  const fullDescription = ((description) => {
    if (optional && isPresent(description)) {
      return `(Optional) ${description}`;
    }

    if (optional && !isPresent(description)) {
      return "(Optional)";
    }

    return description;
  })(description);

  const openMenu = (event) => {
    menuRef.current.event = event;
    setMenuOpen(true);
    event.stopPropagation();
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuClick = (event, callback) => {
    closeMenu();
    callback(event);
  };

  return (
    <>
      <div className={`prompt-wrapper prompt-type-${type}`}>
        <IonList inset mode="ios">
          {isPresent(title) && (
            <IonListHeader mode="ios">
              <IonLabel className="prompt-title">{title}</IonLabel>
              {hasMenu && (
                <>
                  <IonButton onClick={openMenu}>
                    <IonIcon icon={ellipsisHorizontal} color="dark" />
                  </IonButton>
                  <IonPopover
                    className="prompt-wrapper-menu"
                    ref={menuRef}
                    isOpen={menuOpen}
                    onDidDismiss={closeMenu}
                  >
                    {menuItems.map((item, index) => (
                      <IonList key={item.title}>
                        <IonItem
                          button
                          className="ion-text-wrap"
                          lines={
                            index + 1 === menuItems.length ? "none" : "full"
                          }
                          onClick={(e) => handleMenuClick(e, item.onClick)}
                        >
                          <IonLabel>{item.title}</IonLabel>
                        </IonItem>
                      </IonList>
                    ))}
                  </IonPopover>
                </>
              )}
            </IonListHeader>
          )}
          {isPresent(fullDescription) && (
            <IonItem lines="none">
              <IonNote>
                <div
                  className="description"
                  dangerouslySetInnerHTML={renderMarkdownToHTML(
                    fullDescription
                  )}
                />
              </IonNote>
            </IonItem>
          )}
          {children}
        </IonList>
      </div>
    </>
  );
};

export default PromptWrapper;
