import React, { useEffect, useRef, useState } from "react";
import { Deploy } from "cordova-plugin-ionic";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signout } from "../features/users/userSlice";
import {
  enumeratePendingUploads,
  performUpload,
} from "../features/photos/photoUploaderSlice";
import PhotoGrid from "../components/PhotoGrid";
import useDatabase from "../hooks/useDatabase";

const Profile = () => {
  const db = useDatabase();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const photoUploaderState = useSelector((state) => state.photoUploader);
  const [revision, setRevision] = useState(null);
  const photoModal = useRef();

  const logout = () => {
    dispatch(signout({ db }));
    history.replace("/login");
  };

  const reset = async () => {
    db.clear();
    logout();
  };

  useEffect(() => {
    Deploy.getCurrentVersion()
      .then((deployInfo) => {
        if (deployInfo) {
          setRevision(deployInfo.buildId);
        } else {
          setRevision("Unavailable");
        }
      })
      .catch(() => {
        setRevision("Error retrieving version");
      });
  }, []);

  useEffect(() => {
    if (photoUploaderState.status === "idle") {
      dispatch(enumeratePendingUploads({ db }));
    }
  }, [photoUploaderState.status]);

  const numPhotosToUpload = photoUploaderState.pendingUploadKeys.length;

  return (
    <IonPage className="shaded">
      <IonHeader translucent="true" border="false">
        <IonToolbar>
          <IonTitle>
            <img
              src="/assets/netvendor-mark.png"
              alt="NetVendor"
              width="26"
              height="21"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen="true">
        <IonListHeader>
          <IonLabel>Profile</IonLabel>
        </IonListHeader>
        <IonList inset mode="ios">
          <IonItem>
            <IonLabel>
              <h2>{user.name || "Unknown Name"}</h2>
              <h3>{user.email}</h3>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={logout}>
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonList>

        <IonListHeader>
          <IonLabel>Debug</IonLabel>
        </IonListHeader>
        <IonList inset mode="ios">
          <IonItem>
            <IonLabel>
              {`SHA: ${String(process.env.REACT_APP_GIT_SHA).slice(0, 10)}`}
            </IonLabel>
          </IonItem>
          {revision && (
            <IonItem>
              <IonLabel>{`Build: ${revision}`}</IonLabel>
            </IonItem>
          )}
          <IonItem>
            <IonLabel className="ion-text-wrap">
              Photo Uploads
              <p>
                Status: &nbsp;
                {photoUploaderState.status}
                {photoUploaderState.status === "uploading" && (
                  <>
                    &nbsp;
                    {`(${
                      photoUploaderState.currentUploadIndex + 1
                    } of ${numPhotosToUpload})`}
                  </>
                )}
              </p>
              <p>
                Photos to upload: &nbsp;
                {numPhotosToUpload}
              </p>
              {photoUploaderState.lastError && (
                <p>
                  Last Error: &nbsp;
                  {photoUploaderState.lastError}
                </p>
              )}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            disabled={
              photoUploaderState.status !== "idle" || numPhotosToUpload === 0
            }
            onClick={() => dispatch(performUpload({ db }))}
          >
            <IonLabel>Start Photo Upload</IonLabel>
          </IonItem>
          <IonItem
            id="open-photo-modal"
            button
            disabled={numPhotosToUpload === 0}
          >
            <IonLabel>View Photos to Upload</IonLabel>
          </IonItem>
        </IonList>

        <IonModal ref={photoModal} trigger="open-photo-modal">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => photoModal.current?.dismiss()}>
                  Cancel
                </IonButton>
              </IonButtons>
              <IonTitle>Waiting to Upload</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <PhotoGrid
              keys={photoUploaderState.pendingUploadKeys}
              uploadedKeys={photoUploaderState.batchUploadedKeys}
              currentUploadKey={photoUploaderState.currentUploadKey}
            />
          </IonContent>
        </IonModal>

        <IonListHeader>
          <IonLabel>Danger Zone</IonLabel>
        </IonListHeader>
        <IonList inset mode="ios">
          <IonItem button onClick={reset}>
            <IonLabel className="ion-text-wrap">
              Reset Local Data
              <p>
                Warning: All data will be removed from this device! All
                unsubmitted drafts will be lost.
              </p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
