import React from "react";
import Prompt from "./Prompt";

const Prompts = ({ prompts, viewOnly }) => {
  return prompts.map((prompt) => {
    return <Prompt key={prompt.path} prompt={prompt} viewOnly={viewOnly} />;
  });
};

export default Prompts;
