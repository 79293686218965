import { useHistory } from "react-router";
import useDatabase from "./useDatabase";

const useLoggedInRedirection = () => {
  const db = useDatabase();
  const history = useHistory();

  const handleLoggedInRedirection = () => {
    db.get("returnTo").then((returnTo) => {
      if (returnTo) {
        db.remove("returnTo").then(() => {
          history.replace(returnTo);
        });
      } else {
        history.replace("/main/home");
      }
    });
  };

  return handleLoggedInRedirection;
};

export default useLoggedInRedirection;
