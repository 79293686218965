import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonAccordion,
  IonAccordionGroup,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { chevronBack, trashOutline } from "ionicons/icons";
import { useParams, useHistory } from "react-router-dom";
import Sections from "../features/inspections/Sections";
import Prompts from "../features/inspections/Prompts";
import EmptyStateContainer from "./EmptyStateContainer";
import { displayErrorMessage, displayToastMessage } from "../features/appSlice";
import {
  inspectionLoaded,
  inspectionUnloaded,
} from "../features/inspections/localInspectionSlice";
import {
  selectInspectionById,
  saveInspection,
  deleteInspection,
  fetchInspections,
  reset as resetInspections,
} from "../features/inspections/inspectionsSlice";

import InspectionModel, {
  InspectionSchemaVersion2,
} from "../models/Inspection.model";
import renderMarkdownToHTML from "../utils/renderMarkdownToHTML";
import useLogger from "../hooks/useLogger";
import useDatabase from "../hooks/useDatabase";

const Inspection = () => {
  const db = useDatabase();
  const { inspectionId } = useParams();
  const history = useHistory();
  const logger = useLogger();

  const [didFetchInspections, setDidFetchInspections] = useState(false);
  const [inspectionNotFound, setInspectionNotFound] = useState(false);
  const [ready, setReady] = useState(false);

  const user = useSelector((state) => state.user.user);
  const inspection = useSelector((state) =>
    selectInspectionById(state, inspectionId)
  );

  /**
   * A note about the "pending responses" on the local inspection slice:
   * The logic here ensures that the current pending responses match
   * the currently focused inspection, since there can be a discrepancy
   * (usually just a small delay) between what is in the inspections slice
   * and the localInspection slice. This indicates a smell that we should
   * eliminate the concept of the "localInspection" slice altogether, and
   * move in the direction of simply using the inspections slice.
   *
   * https://github.com/servus/servus-universal/issues/59
   *
   * Until then, the pendingResponses are qualified with the current inspection.
   * This ensures that we don't show a previously edited draft's data erroneously
   * alongside this inspection.
   */
  const pendingResponses = useSelector((state) =>
    state.localInspection?.uuid === inspectionId
      ? state.localInspection.pendingResponses
      : {}
  );

  const isDirty = useSelector(
    (state) => state.localInspection.status === "dirty"
  );
  const operationStatus = useSelector((state) => state.inspections.status);
  const errorMessage = useSelector((state) => state.inspections.error);
  const loadingMessage =
    (operationStatus === "loading" && "Loading") ||
    (operationStatus === "saving" && "Saving");
  const isLoading = Boolean(loadingMessage) || !ready;
  const isSubmitted = () => inspection?.status === "submitted";
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const inspectionModel = !ready
    ? new InspectionModel()
    : new InspectionModel({
        template: {
          form: inspection?.template?.form || {},
        },
        responses: pendingResponses,
      });

  const promptToDeleteInspection = () => {
    presentAlert({
      header: "Delete this inspection?",
      message:
        "This entire inspection will be deleted. This action cannot be undone.",
      buttons: ["Cancel", { text: "Delete", handler: doDeleteInspection }],
    });
  };

  const doDeleteInspection = () => {
    dispatch(deleteInspection({ db, inspection }));
    goBack();
  };

  const goBack = () => {
    dispatch(resetInspections());
    dispatch(inspectionUnloaded());
    if (inspection?.submitted_at) {
      history.push("/main/inspections");
    } else {
      history.push("/main/drafts");
    }
  };

  const logInspectionLoaded = async () => {
    logger.info("[InspectionOpened] Database stats", {
      length: await db.length(),
      driver: db.driver,
    });
  };

  // Handle bootstrapping the inspection
  useEffect(() => {
    if (inspection) {
      /**
       * In this case the inspection was found in the redux store, so we record
       * that we've loaded it.
       *
       * Since there is a delay between the inspection being loaded and the
       * localInspection slice data being initialized via inspectionLoaded,
       * the concept of "ready" has been introduced. We delay rendering the
       * Inspection until it is "ready" to avoid some bugs with stale data.
       *
       * This will be rectified with: https://github.com/servus/servus-universal/issues/5
       */
      dispatch(inspectionLoaded(inspection));
      setReady(true);
      logInspectionLoaded();
    } else if (!didFetchInspections) {
      /**
       * In this case, the inspection was not found in the redux store,
       * but it is probably available in the database, so we fetch from the
       * database first.
       *
       * After loading from the database, the component will re-render
       * and the inspection will be found via the first branch of the `if`.
       */
      dispatch(fetchInspections({ db, user })).then(() => {
        setInspectionNotFound(false);
        setDidFetchInspections(true);
      });
    } else {
      /**
       * In this case, the inspection was simply not found.
       *
       */
      setInspectionNotFound(true);
    }
  }, [inspectionId, inspection, didFetchInspections]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(displayErrorMessage(errorMessage));
    }
  }, [errorMessage]);

  const persistInspection = (status) => {
    const updatedInspection = { ...inspection };
    updatedInspection.status = status;
    updatedInspection.updated_at = new Date().toJSON();
    updatedInspection.responses = pendingResponses;
    updatedInspection.percent_complete = inspectionModel.percentComplete;
    updatedInspection.schema_version = InspectionSchemaVersion2;
    if (status === "submitted") {
      updatedInspection.submitted_at = new Date().toJSON();
    }

    return dispatch(saveInspection({ db, inspection: updatedInspection }));
  };

  const submitInspection = () => {
    persistInspection("submitted").then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        dispatch(displayToastMessage("Your inspection has been submitted!"));
        dispatch(inspectionUnloaded);
        history.replace("/main/inspections");
      }
    });
  };

  const submitDraft = () => {
    persistInspection("draft").then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        history.replace("/main/drafts");
      }
    });
  };

  const renderSubmit = () => {
    if (inspectionModel.complete) {
      return (
        <IonButton
          fill="solid"
          color="primary"
          onClick={submitInspection}
          size="large"
        >
          Submit
        </IonButton>
      );
    }

    return (
      <IonBadge color="light">
        {inspectionModel.percentComplete}% Complete
      </IonBadge>
    );
  };

  const handleBackNavigation = () => {
    if (isDirty) {
      presentAlert({
        header: "There are unsaved changes",
        message:
          "If you leave now, you will lose your unsaved changes. Stay here and use the \"Save Draft\" button at the bottom of the page to save your changes.",
        buttons: [{ text: "Leave Page", handler: () => goBack() }, "Stay Here"],
      });
    } else {
      goBack();
    }
  };

  if (inspectionNotFound) {
    return (
      <IonPage className="page-inspection">
        <IonContent fullscreen="true">
          <EmptyStateContainer
            title="Inspection Not Found"
            linkText="Go Home"
            linkUrl="/main/drafts"
          />
        </IonContent>
      </IonPage>
    );
  }

  if (!inspection) {
    return null;
  }

  return (
    <IonPage className="page-inspection">
      <>
        <IonHeader translucent="true" collapse="fade">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleBackNavigation}>
                <IonIcon slot="start" icon={chevronBack} />
                Back
              </IonButton>
            </IonButtons>
            {!isSubmitted() && (
              <IonButtons slot="end">
                <IonButton onClick={promptToDeleteInspection}>
                  <IonIcon icon={trashOutline} color="danger" />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen="true">
          {ready && (
            <>
              <div className="inspection-details">
                <IonAccordionGroup>
                  <IonAccordion value="inspection-details">
                    <IonItem slot="header" lines="none">
                      <dl>
                        <dt>Unit</dt>
                        <dd>{inspection.unit.name}</dd>
                        <dt>Inspection Type</dt>
                        <dd>{inspection.template.title}</dd>
                      </dl>
                    </IonItem>
                    <IonItem slot="content" lines="none">
                      <dl>
                        <dt>Property</dt>
                        <dd>{inspection.property.name}</dd>
                        {inspection.template.description && (
                          <>
                            <dt>About this Inspection</dt>
                            <dd>
                              <div
                                dangerouslySetInnerHTML={renderMarkdownToHTML(
                                  inspection.template.description
                                )}
                              />
                            </dd>
                          </>
                        )}
                        <dt>Inspector</dt>
                        <dd>{inspection.user.name || inspection.user.email}</dd>
                        <dt>Started</dt>
                        <dd>
                          {new Date(inspection.started_at).toLocaleString(
                            "en-US"
                          )}
                        </dd>
                        {isSubmitted() && (
                          <>
                            <dt>Submitted</dt>
                            <dd>
                              {new Date(inspection.submitted_at).toLocaleString(
                                "en-US"
                              )}
                            </dd>
                          </>
                        )}
                      </dl>
                    </IonItem>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>

              <Prompts
                prompts={inspectionModel.topLevelPrompts}
                viewOnly={inspection.state === "submitted"}
              />
              <Sections sections={inspectionModel.sections} />
            </>
          )}
        </IonContent>

        <IonLoading isOpen={isLoading} message={loadingMessage || "Loading"} />

        {!isSubmitted() && (
          <IonFooter className="footer-toolbar-padding">
            <IonToolbar>
              <IonButtons slot="end">{renderSubmit()}</IonButtons>
              <IonButtons slot="start">
                <IonButton
                  fill="outline"
                  color="medium"
                  onClick={submitDraft}
                  size="large"
                >
                  Save Draft
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        )}
      </>
    </IonPage>
  );
};

export default Inspection;
