import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import EmptyStateContainer from "../components/EmptyStateContainer";
import MainPage from "../components/MainPage";
import {
  fetchInspections,
  selectSubmittedInspections,
} from "../features/inspections/inspectionsSlice";
import useDatabase from "../hooks/useDatabase";

const Inspections = () => {
  const db = useDatabase();
  const inspections = useSelector(selectSubmittedInspections);
  const isLoading = useSelector(
    (state) => state.submittedInspections.status === "loading"
  );
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInspections({ db, user }));
  }, []);

  return (
    <MainPage title="Completed Inspections">
      {isLoading && <EmptyStateContainer title="Loading..." />}
      {!isLoading && inspections.length === 0 && (
        <EmptyStateContainer title="You have no completed inspections" />
      )}
      <IonList>
        {inspections.map((inspection) => (
          <IonItem
            key={inspection.uuid}
            routerLink={`/main/inspection/${inspection.uuid}`}
          >
            <IonLabel>
              <h2>{`Unit ${inspection.unit.name}`}</h2>
              <h3>{inspection.property.name}</h3>
              <h3>{inspection.template.title}</h3>
              <p>
                Started:&nbsp;
                {new Date(inspection.started_at).toLocaleString("en-US")}
              </p>
              <p>
                Submitted:&nbsp;
                {new Date(inspection.submitted_at).toLocaleString("en-US")}
              </p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </MainPage>
  );
};

export default Inspections;
