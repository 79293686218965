import React from "react";
import HelpPage from "../components/HelpPage";

const GettingStarted = () => {
  return (
    <HelpPage>
      <h1>Getting Started</h1>

      <p>
        To start a new inspection, go to &quot;Drafts&quot; and click the
        &quot;+&quot; button.
      </p>

      <p>
        You can &quot;Save&quot; or &quot;Submit&quot; your draft inspections.
        Saved inspections stay on your device until you submit them.
      </p>

      <p>Once submitted, you can&apos;t edit the inspection.</p>
    </HelpPage>
  );
};

export default GettingStarted;
