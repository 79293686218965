import React, { useEffect, useState } from "react";
import { Drivers, Storage } from "@ionic/storage";

import DatabaseContext from "./contexts/DatabaseContext";
import useLogger from "./hooks/useLogger";
import { displayFatalErrorMessage } from "./features/appSlice";

const DatabaseProvider = ({ children }) => {
  const [db, setDb] = useState(null);
  const logger = useLogger();

  useEffect(() => {
    async function createDb() {
      try {
        const storage = new Storage({
          name: "ServusConnectDB",
          storeName: "app",
          driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        });
        await storage.create();
        setDb(storage);
      } catch (e) {
        const message = `There was an error connecting to device storage.
          Please reload to try again. If the problem persists, try rebooting your device.`;
        logger.error(e);
        displayFatalErrorMessage(message);
      }
    }

    createDb();

    return () => setDb(null);
  }, []);

  if (!db) {
    return null;
  }

  return (
    <DatabaseContext.Provider value={db}>{children}</DatabaseContext.Provider>
  );
};

export default DatabaseProvider;
