import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonToggle,
} from "@ionic/react";
import { ellipseOutline, checkmarkDoneCircleOutline } from "ionicons/icons";
import Modal from "../../components/Modal";
import Prompts from "./Prompts";
import PromptWrapper from "./prompts/PromptWrapper";

import { updateSectionSkipped } from "./localInspectionSlice";
import renderMarkdownToHTML from "../../utils/renderMarkdownToHTML";

const SectionIcon = ({ enabled, complete }) => {
  const icon =
    complete || !enabled ? checkmarkDoneCircleOutline : ellipseOutline;
  const color = complete && enabled ? "success" : "medium";

  return <IonIcon slot="start" icon={icon} color={color} />;
};

const SectionItem = ({
  section,
  last,
  setFocusedSectionUuid,
  handleSectionApplicableToggle,
}) => {
  const lines = last ? "none" : "full";
  const progressText = () => {
    const optionalPromptCount =
      section.promptCount - section.requiredPromptCount;
    const showOptionalCount =
      optionalPromptCount > 0 && section.answerCount < section.promptCount;
    const optionalPromptText = showOptionalCount
      ? ` (${optionalPromptCount} optional)`
      : "";

    return section.disabled
      ? "Section N/A"
      : `${section.answerCount} out of ${section.promptCount}${optionalPromptText}`;
  };

  return (
    <>
      <IonItem
        button
        onClick={() => setFocusedSectionUuid(section.uuid)}
        lines={lines}
      >
        <SectionIcon complete={section.complete} enabled={section.enabled} />
        <IonLabel
          color={section.enabled ? "dark" : "medium"}
          className="ion-text-wrap"
        >
          <h2>{section.title}</h2>
          {section.description && (
            <div
              className="description"
              dangerouslySetInnerHTML={renderMarkdownToHTML(
                section.description
              )}
            />
          )}
          <p>{progressText()}</p>
        </IonLabel>
        {section.optional && (
          <IonToggle
            onIonChange={(e) => handleSectionApplicableToggle(e, section)}
            onClick={(e) => e.stopPropagation()}
            checked={section.enabled}
            slot="end"
          />
        )}
      </IonItem>
    </>
  );
};

const Sections = ({ sections }) => {
  const dispatch = useDispatch();
  const [focusedSectionUuid, setFocusedSectionUuid] = useState(null);
  const closeModal = () => setFocusedSectionUuid(null);
  const sectionCount = sections.length;
  const focusedSection = sections.find(
    (section) => section.uuid === focusedSectionUuid
  );

  const handleSectionApplicableToggle = (e, section) => {
    if (e.detail.checked !== section.enabled) {
      dispatch(
        updateSectionSkipped({
          skipped: !e.detail.checked,
          sectionUuid: section.uuid,
        })
      );
    }
  };

  if (sections.length === 0) {
    return null;
  }

  return (
    <>
      <PromptWrapper type="sections">
        {sections.map((section, index) => (
          <SectionItem
            key={section.uuid}
            section={section}
            last={index + 1 === sectionCount}
            setFocusedSectionUuid={setFocusedSectionUuid}
            handleSectionApplicableToggle={handleSectionApplicableToggle}
          />
        ))}
      </PromptWrapper>
      <Modal
        isOpen={!!focusedSectionUuid}
        title={focusedSection?.title}
        dismissModal={closeModal}
        onDidDismiss={closeModal}
        pageClassName="page-inspection"
      >
        {focusedSection?.description && (
          <IonList inset mode="ios">
            <IonItem lines="none">
              <IonNote>
                <div
                  className="description"
                  dangerouslySetInnerHTML={renderMarkdownToHTML(
                    focusedSection?.description
                  )}
                />
              </IonNote>
            </IonItem>
          </IonList>
        )}
        {focusedSection?.optional && (
          <IonList inset mode="ios">
            <IonListHeader mode="ios">Section Applicable?</IonListHeader>
            <IonItem lines="none">
              <IonToggle
                slot="end"
                onIonChange={(e) =>
                  handleSectionApplicableToggle(e, focusedSection)
                }
                checked={focusedSection.enabled}
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
              />
              <IonNote>
                <div className="description">
                  {`This section may be skipped if "${focusedSection.title}" is not applicable to this inspection.`}
                </div>
              </IonNote>
            </IonItem>
          </IonList>
        )}
        {focusedSection?.enabled && (
          <Prompts prompts={focusedSection.prompts} />
        )}
      </Modal>
    </>
  );
};

export default Sections;
