import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import {
  isPlatform,
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonImg,
} from "@ionic/react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mailOutline, logoMicrosoft, lockClosed } from "ionicons/icons";
import { Browser } from "@capacitor/browser";
import useLogger from "../hooks/useLogger";
import * as PKCE from "../utils/pkce";
import { signin } from "../features/users/userSlice";
import {
  appIsLoading,
  appDoneLoading,
  clearErrorMessage,
  displayErrorMessage,
} from "../features/appSlice";
import "./Login.css";
import useLoggedInRedirection from "../hooks/useLoggedInRedirection";
import { computeCodeChallenge } from "../utils/pkce";
import { emailDomain, withCustomScheme } from "../utils/helpers";
import {
  usePostOauth2TokenMutation,
  useLazyGetUserSessionQuery,
  useLazyGetLoginMethodsQuery,
} from "../utils/servus_api_rtk";
import useDatabase from "../hooks/useDatabase";

const Login = () => {
  const db = useDatabase();
  const [pane, setPane] = useState("step1");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordLoginAllowed, setPasswordLoginAllowed] = useState(true);
  const [ssoLoginAllowed, setSsoLoginAllowed] = useState(false);
  const [ssoProvider, setSsoProvider] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLoggedInRedirection = useLoggedInRedirection();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const authCode = queryParams.get("code");
  const [getToken] = usePostOauth2TokenMutation();
  const [getUserSession] = useLazyGetUserSessionQuery();
  const [getLoginMethods, { isFetching: isFetchingLoginMethods }] =
    useLazyGetLoginMethodsQuery();
  const logger = useLogger();

  const redirectUriBase =
    process.env.REACT_APP_SSO_REDIRECT_URI_BASE || "http://localhost:3000";
  const loginUri = new URL("/login", redirectUriBase).toString();
  const cognitoRedirectUri = isPlatform("hybrid")
    ? withCustomScheme(loginUri)
    : loginUri;

  useEffect(() => {
    if (authCode) {
      setPane("ssoLoginToken");
      getToken({
        client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
        code: authCode,
        code_verifier: window.sessionStorage.getItem("code_verifier"),
        redirect_uri: cognitoRedirectUri,
      })
        .unwrap()
        .then((response) => {
          if (response.access_token) {
            getUserSession(response.access_token)
              .unwrap()
              .then((getUserSessionResponse) => {
                const sessionAttributes =
                  getUserSessionResponse.data.attributes;
                const user = {
                  api_key: response.access_token,
                  email: sessionAttributes.user_email,
                  name: sessionAttributes.user_name,
                  id: sessionAttributes.user_id,
                };
                dispatch({ type: "user/signedIn", payload: user });
                db.set("user", user);
                Bugsnag.setUser(user.id, user.email, user.name);
                handleLoggedInRedirection();
              });
          } else {
            setPane("waitingRoom");
          }
        })
        .catch((error) => {
          displayError("Authentication Error");
          logger.info("SSO Authentication Error", error);
          reset();
        });
    }
  }, [authCode]);

  const displayError = (message) => {
    dispatch(displayErrorMessage(message));
  };

  const clearError = () => {
    dispatch(clearErrorMessage());
  };

  const handleSubmit = () => {
    const onError = (err) => {
      displayError(err);
      setPassword("");
      setIsSubmitting(false);
    };

    const onSuccess = () => {
      handleLoggedInRedirection();
    };

    setIsSubmitting(true);
    clearError();
    dispatch(signin({ db, email, password, onSuccess, onError }));
  };

  const handleChange = (event) => {
    clearError();
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  const reset = () => {
    setEmail(null);
    setPane("step1");
    history.push("/login");
  };

  const cognitoAuthorizeUrl = (codeChallenge) => {
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
      idp_identifier: emailDomain(email),
      response_type: "code",
      scope: "openid",
      redirect_uri: cognitoRedirectUri,
      code_challenge_method: "S256",
      code_challenge: codeChallenge,
    });

    return `${process.env.REACT_APP_AWS_COGNITO_ENDPOINT}/oauth2/authorize?${params}`;
  };

  const startSsoLogin = async () => {
    setPane("ssoLogin");

    const codeVerifier = PKCE.generateCodeVerifier();
    const codeChallenge = await computeCodeChallenge(codeVerifier);
    window.sessionStorage.setItem("code_verifier", codeVerifier);

    try {
      await Browser.open({
        url: cognitoAuthorizeUrl(codeChallenge),
        windowName: "_self",
      });
    } catch (error) {
      displayError("Could not open browser to perform login");
      logger.info("SSO Browser Error", error);
      reset();
    }
  };

  const continueLogin = () => {
    dispatch(appIsLoading());
    getLoginMethods({ email })
      .unwrap()
      .then(({ data: { attributes } }) => {
        setPasswordLoginAllowed(attributes.password);
        setSsoProvider(attributes.sso_provider);
        setSsoLoginAllowed(!!attributes.sso_provider);
        setPane("step2");
      })
      .catch((error) => {
        logger.error("Error obtaining login methods", {}, error);
        displayError("Please enter a valid email address");
      })
      .finally(() => dispatch(appDoneLoading()));
  };

  const ssoLogo = ssoProvider === "microsoft" ? logoMicrosoft : lockClosed;
  const ssoProviderLabel = (() => {
    switch (ssoProvider) {
      case "microsoft":
        return "Microsoft";
      case "cyberark":
        return "CyberArk SSO";
      default:
        return "SSO";
    }
  })();

  return (
    <IonPage>
      <IonContent className="login-page">
        <div className="login-container">
          <div className="login-header">
            <IonImg
              className="login-container-image"
              src="/assets/servus-inspections-image.png"
            />
            <IonImg
              className="login-container-logo"
              src="/assets/logo-inspections-with-logomark.svg"
            />
          </div>
          <div className="login-controls">
            {pane === "step1" && (
              <>
                <label htmlFor="email">
                  Email Address
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                  />
                </label>
                <IonButton
                  size="default"
                  fill="outline"
                  expand="block"
                  onClick={continueLogin}
                  disabled={!email || isFetchingLoginMethods}
                >
                  Continue
                </IonButton>
              </>
            )}
            {pane === "step2" && (
              <>
                <label htmlFor="email">
                  Email Address
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    disabled
                  />
                </label>

                {ssoLoginAllowed && (
                  <>
                    <IonButton
                      size="default"
                      expand="block"
                      onClick={startSsoLogin}
                    >
                      <IonIcon slot="start" icon={ssoLogo} />
                      Log In With {ssoProviderLabel}
                    </IonButton>
                    {passwordLoginAllowed && (
                      <div className="or-divider">OR</div>
                    )}
                  </>
                )}

                {passwordLoginAllowed && (
                  <>
                    <label htmlFor="password">
                      Password
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={handleChange}
                      />
                    </label>

                    <IonButton
                      size="default"
                      expand="block"
                      onClick={handleSubmit}
                      disabled={!password || isSubmitting}
                    >
                      <IonIcon slot="start" icon={mailOutline} />
                      Log In With Password
                    </IonButton>
                  </>
                )}
              </>
            )}
            {pane === "ssoLogin" && (
              <>
                <p>You are now being directed to your corporate login...</p>
              </>
            )}
            {pane === "ssoLoginToken" && <p>Fetching token...</p>}
            {pane === "waitingRoom" && (
              <>
                <p>
                  You have been logged in to NetVendor Maintenance with your
                  corporate email. However, you do not yet have a valid
                  NetVendor Maintenance account.
                </p>
                <p>
                  Please consult with your Property Manager, Maintenance
                  Supervisor, or Regional Manager and ask them to add you to
                  NetVendor Maintenance. Once you have been added, NetVendor
                  Maintenance will notify you via a Welcome email.
                </p>
              </>
            )}
            {pane !== "step1" && (
              <IonButton
                size="small"
                fill="clear"
                style={{ marginTop: "36px" }}
                onClick={reset}
              >
                Start Over
              </IonButton>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
