import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonBadge,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonRadioGroup,
  IonRadio,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonModal,
  IonTextarea,
} from "@ionic/react";
import {
  cameraOutline,
  documentTextOutline,
  checkmarkSharp,
} from "ionicons/icons";
import { removePendingResponse } from "../localInspectionSlice";
import PromptWrapper from "./PromptWrapper";
import "./Radio.css";
import Photo from "../../../components/Photo";
import usePhotoGallery from "../../../hooks/usePhotoGallery";
import usePhotoErrorHandler from "../../../hooks/usePhotoErrorHandler";
import { saveResponsePartial } from "../localInspectionSlice";

const Radio = ({ prompt }) => {
  const dispatch = useDispatch();
  const [modalPhotos, setModalPhotos] = useState([]);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const { takePhoto } = usePhotoGallery();
  const handlePhotoError = usePhotoErrorHandler();

  const dismissPhotoModal = () => setShowPhotoModal(false);
  const dismissNoteModal = () => setShowNoteModal(false);

  const response = prompt.response;

  const setInResponse = (key, value) => {
    dispatch(
      saveResponsePartial({ path: prompt.path, type: "radio", key, value })
    );
  };

  const handleRadioChange = (event, value) => {
    if (event?.target?.checked) {
      setInResponse("value", value);
    } else {
      reset();
    }
  };

  const reset = () => {
    dispatch(removePendingResponse({ path: prompt.path }));
  };

  const openPhotoModal = () => {
    setModalPhotos(response.photos || []);
    setShowPhotoModal(true);
  };

  const openNoteModal = () => {
    setShowNoteModal(true);
  };

  const savePhotos = () => {
    setInResponse("photos", modalPhotos);
    dismissPhotoModal();
    setModalPhotos([]);
  };

  const saveNote = () => {
    dismissNoteModal();
  };

  const handleNoteChange = (value) => {
    setInResponse("note", value);
  };

  const takeModalPhoto = () => {
    takePhoto()
      .then((photo) =>
        setModalPhotos((currentPhotos) => [photo, ...currentPhotos])
      )
      .catch((error) => handlePhotoError(error));
  };

  return (
    <PromptWrapper
      type="radio"
      title={prompt.title}
      description={prompt.description}
      optional={prompt.optional}
    >
      <IonItem lines="none">
        <IonGrid style={{ padding: 0 }}>
          <IonRow>
            <IonCol size="10">
              <IonRadioGroup
                allowEmptySelection
                value={response.value}
                name={prompt.uuid}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr)",
                  columnGap: "5px",
                  rowGap: "5px",
                }}
              >
                {prompt.data.choices.map((choice) => {
                  const cssBorderColor = `rgba(var(--ion-color-${choice.color}-rgb), 0.25)`;
                  return (
                    <div
                      key={choice.value}
                      style={{
                        border: `1px solid ${cssBorderColor}`,
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    >
                      <IonLabel
                        className="ion-text-wrap"
                        style={{
                          padding: "0",
                          position: "relative",
                          height: "100%",
                        }}
                        onClick={(e) => handleRadioChange(e, choice.value)}
                      >
                        <IonRadio
                          value={choice.value}
                          mode="md"
                          color={choice.color}
                          style={{ marginTop: "0" }}
                        />
                        {choice.label}
                      </IonLabel>
                    </div>
                  );
                })}
              </IonRadioGroup>
            </IonCol>
            <IonCol size="2" className="last">
              <div className="control-icon control-icon-camera">
                <IonIcon
                  style={{ fontSize: "32px" }}
                  icon={cameraOutline}
                  onClick={openPhotoModal}
                />
                {response.photos.length > 0 ? (
                  <IonBadge color="success">{response.photos.length}</IonBadge>
                ) : (
                  <IonBadge color="medium">0</IonBadge>
                )}
              </div>
              <div className="control-icon">
                <IonIcon
                  style={{ fontSize: "32px" }}
                  icon={documentTextOutline}
                  onClick={() => openNoteModal()}
                />
                {response.note && (
                  <IonBadge color="success">
                    <IonIcon icon={checkmarkSharp} />
                  </IonBadge>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <IonModal isOpen={showPhotoModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={dismissPhotoModal}>Close</IonButton>
            </IonButtons>
            <IonTitle>Photos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              {modalPhotos.map((photo) => (
                <IonCol size="6" key={photo.fileName}>
                  <Photo photo={photo} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          <IonButton fill="outline" onClick={takeModalPhoto}>
            <IonIcon slot="start" icon={cameraOutline} />
            Add Photo
          </IonButton>
          <IonButton expand="block" onClick={savePhotos}>
            Save
          </IonButton>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showNoteModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={dismissNoteModal}>Close</IonButton>
            </IonButtons>
            <IonTitle>Note</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonTextarea
            rows="5"
            placeholder="Enter your note..."
            value={response.note}
            onIonChange={(e) => handleNoteChange(e.detail.value)}
          />
          <IonButton expand="block" onClick={saveNote}>
            Save
          </IonButton>
        </IonContent>
      </IonModal>
    </PromptWrapper>
  );
};

export default Radio;
