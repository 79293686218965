import React, { useEffect } from "react";
import { IonLoading } from "@ionic/react";
import { Redirect, Route, useLocation, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import useDatabase from "../hooks/useDatabase";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const db = useDatabase();
  const location = useLocation();
  const match = useRouteMatch(rest.path);
  const isLoading = useSelector((state) => state.user.status === "loading");
  const isAuthenticated = useSelector(
    (state) =>
      state.user.user &&
      (state.user.user.authentication_token || state.user.user.api_key)
  );

  useEffect(() => {
    if (match && !isAuthenticated && !isLoading) {
      db.set("returnTo", location);
    }
  }, [JSON.stringify(match), isAuthenticated]);

  if (isLoading) {
    return <IonLoading isOpen />;
  }

  return (
    <Route {...rest}>
      {isAuthenticated && <Component />}
      {!isAuthenticated && <Redirect to="/login" />}
    </Route>
  );
};

export default AuthenticatedRoute;
