import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonToast } from "@ionic/react";
import { clearFatalErrorMessage } from "./features/appSlice";

const FatalAppError = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.app.fatalErrorMessage);
  const isOpen = useSelector((state) => !!state.app.fatalErrorMessage);

  const reload = () => {
    dispatch(clearFatalErrorMessage());
    window.location.reload();
  };

  return (
    <IonToast
      isOpen={isOpen}
      message={message}
      position="top"
      color="danger"
      onDidDismiss={reload}
      buttons={[{ text: "Reload" }]}
    />
  );
};

export default FatalAppError;
