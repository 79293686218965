import React from "react";
import { useDispatch } from "react-redux";
import { IonButton, IonTextarea, IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { removeRepeater, updateGroupLabel } from "../localInspectionSlice";
import Prompts from "../Prompts";
import renderMarkdownToHTML from "../../../utils/renderMarkdownToHTML";

const Repeater = ({ prompt, groupPrompt, viewOnly, removable }) => {
  const dispatch = useDispatch();
  const repeaterKey = prompt.repeaterKey;
  const path = prompt.path;

  const onRemoveRepeater = () => {
    dispatch(removeRepeater({ path: groupPrompt.path, repeaterKey }));
  };

  const onLabelChange = (event) => {
    const value = event.detail.value;
    dispatch(updateGroupLabel({ path, value }));
  };

  const labelFromRepeater = prompt.response?.value;
  const labelFromGroup = (groupPrompt.response?.labels || {})[repeaterKey];
  const label = labelFromRepeater || labelFromGroup;

  return (
    <div key={`repeater-${repeaterKey}`} className="prompt-type-group__prompts">
      <div
        className={
          prompt.data.repeatable ? "prompt-type-group__repeater-header" : ""
        }
      >
        <div
          className={
            prompt.data.repeatable
              ? "prompt-type-group__repeater-label-header"
              : ""
          }
        >
          <div className="prompt-type-group__repeater-label-title">
            {prompt.data?.labelable?.title}
          </div>
          {removable && (
            <IonButton fill="clear" onClick={onRemoveRepeater} size="small">
              <IonIcon
                icon={trashOutline}
                color="danger"
                size="small"
                slot="end"
              />
            </IonButton>
          )}
        </div>
        {prompt.data?.labelable?.description && (
          <div
            className="description"
            dangerouslySetInnerHTML={renderMarkdownToHTML(
              prompt.data.labelable.description
            )}
          />
        )}
        {prompt.data?.labelable && (
          <div className="prompt-type-group__repeater-label">
            <IonTextarea
              rows={1}
              value={label}
              name={`${path}-label`}
              onIonChange={onLabelChange}
            />
          </div>
        )}
      </div>
      <Prompts prompts={prompt.prompts} viewOnly={viewOnly} />
    </div>
  );
};

export default Repeater;
