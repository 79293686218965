import React from "react";
import { IonRouterLink } from "@ionic/react";
import "./EmptyStateContainer.css";

const EmptyStateContainer = ({ title, linkText, linkUrl }) => (
  <div className="vertical-align-center">
    <div className="empty-state-container">
      <strong>{title}</strong>
      <p>
        <IonRouterLink routerLink={linkUrl} routerDirection="root">
          {linkText}
        </IonRouterLink>
      </p>
    </div>
  </div>
);

export default EmptyStateContainer;
