import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { reauthenticate } from "../features/users/userSlice";
import useDatabase from "../hooks/useDatabase";

const Reauthenticator = () => {
  const db = useDatabase();
  const user = useSelector((state) => state.user.user || {});
  const dispatch = useDispatch();
  const [allowReauthentication, setAllowReauthentication] = useState(true);

  useEffect(() => {
    let promise;

    if (user.authentication_token || user.api_key) {
      setAllowReauthentication(false);

      if (user.api_key) {
        axios.defaults.headers.common.API_KEY = user.api_key;
      } else {
        axios.defaults.headers.common.AUTH_EMAIL = user.email;
        axios.defaults.headers.common.AUTH_TOKEN = user.authentication_token;
      }
    } else if (allowReauthentication) {
      setAllowReauthentication(false);
      promise = dispatch(reauthenticate({ db }));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [user]);

  // Configure Datadog for the user
  useEffect(() => {
    if (user.id) {
      datadogLogs.setUser({
        id: user.id,
        email: user.email,
      });
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
      });
    } else {
      datadogLogs.setUser({});
      datadogRum.setUser({});
    }

    const logLevel = process.env.NODE_ENV === "development" ? "debug" : "info";
    datadogLogs.logger.setLevel(logLevel);

    const handler = process.env.NODE_ENV === "development" ? "console" : "http";
    datadogLogs.logger.setHandler(handler);
  }, [user]);

  return null;
};

export default Reauthenticator;
