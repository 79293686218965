export const isPresent = (value) => !!value;

export const hasOwnProperty = (object, property) => {
  if (!object) {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(object, property);
};

const CUSTOM_SCHEME = "servus-inspections";

export const withCustomScheme = (url) =>
  String(url).replace(/^https?:\/\//, `${CUSTOM_SCHEME}://`);

export const emailDomain = (email) => {
  try {
    return String(email).toLowerCase().split("@")[1] || "";
  } catch {
    return "";
  }
};
