import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonToast } from "@ionic/react";
import { clearErrorMessage } from "./features/appSlice";

const TransientAppError = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.app.transientErrorMessage);
  const isOpen = useSelector((state) => !!state.app.transientErrorMessage);

  const clear = () => {
    dispatch(clearErrorMessage());
  };

  return (
    <IonToast
      isOpen={isOpen}
      message={message}
      position="top"
      color="danger"
      duration={5000}
      onDidDismiss={clear}
      buttons={[{ text: "Dismiss" }]}
    />
  );
};

export default TransientAppError;
