export default class InspectionResponseMigrator {
  static migrate(originalResponses) {
    const migratedResponses = {};

    // Move all responses to the correct path
    Object.entries(originalResponses).forEach(([path, response]) => {
      const modernPath = InspectionResponseMigrator.modernPromptPath(path);
      migratedResponses[modernPath] = response;
    });

    // Move repeaterKeys and labels to the correct response value
    Object.entries(migratedResponses).forEach(([path, response]) => {
      if (response.type === "group") {
        const groupUuid = path.split("/").at(-1);
        const repeaterKeys = response.repeaterKeys;
        const updatedGroupResponse = { ...response };

        if (Array.isArray(repeaterKeys)) {
          updatedGroupResponse.value = repeaterKeys;
          delete updatedGroupResponse.repeaterKeys;
          repeaterKeys.forEach((repeaterKey) => {
            const repeaterPath = `${path}/${groupUuid}[${repeaterKey}]`;
            migratedResponses[repeaterPath] ||= {};
            const label = (response.labels || {})[repeaterKey];
            if (label) {
              migratedResponses[repeaterPath].value = label;
            }
          });
          delete updatedGroupResponse.labels;

          migratedResponses[path] = updatedGroupResponse;
        }
      }
    });

    return migratedResponses;
  }

  /**
   * New rules for groups and repeaters will generate paths (and store responses under paths)
   * like the following.
   *
   * 1. Group Response
   * 2. Repeater Response
   * 3. Nested Prompt Response
   *
   * Current Scheme:
   *
   * 1. prompt.group1
   * 2. prompt.group1/prompt.group1[0] (repeater response)
   * 3. prompt.group1/prompt.group1[0]/prompt.nested (nested prompt response)
   *
   * However, the old rules did not "nest" the repeaters under the group. Instead,
   * the repeaterKey was appended to the non-group prompt path:
   *
   * Old Scheme:
   *
   * 1. prompt.group1
   * 2. (did not exist)
   * 3. prompt.group1/prompt.nested[0]
   *
   * This figures out the modern prompt path from a potentially deprecated prompt path.
   */
  static modernPromptPath(deprecatedPromptPath) {
    const modernPathParts = [];
    const deprecatedPathParts = deprecatedPromptPath.split("/");

    while (deprecatedPathParts.length > 0) {
      const promptPart = deprecatedPathParts.pop();
      const parentPart = deprecatedPathParts.at(-1);

      // prompt.group1/prompt.group2[0]/prompt.nested[1]
      // prompt.group1/prompt.group1[0]/prompt.group2/prompt.group2[1]/prompt.nested
      if (promptPart.endsWith("]") && parentPart) {
        const [, promptUuid, repeaterKey] =
          promptPart.match(/^([^[]+)\[(\w+)\]$/);
        const parentUuid = parentPart.split("[").at(0);

        if (promptUuid === parentUuid) {
          // Modern path already!
          modernPathParts.unshift(promptPart);
        } else {
          modernPathParts.unshift(promptUuid);
          modernPathParts.unshift(`${parentUuid}[${repeaterKey}]`);
        }
      } else {
        modernPathParts.unshift(promptPart);
      }
    }

    return modernPathParts.join("/");
  }
}
