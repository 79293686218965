import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ServusApi, { cacheDurationMs } from "../../utils/servus_api";

const initialState = {
  unitsByPropertyId: {},
  cachedAtByPropertyId: {},
  status: "idle",
};

// A helper to select/initialize state as viewed by a single propertyId
const propertySubstate = (propertyId, globalState) => ({
  units: globalState.unitsByPropertyId[propertyId] || [],
  cachedAt: globalState.cachedAtByPropertyId[propertyId] || 0,
});

const timeToRefetch = (cachedAt) => Date.now() - cachedAt > cacheDurationMs;

export const fetchUnits = createAsyncThunk(
  "units/fetchUnits",
  async (propertyId, { dispatch, getState }) => {
    const state = propertySubstate(propertyId, getState().units);
    if (state.units.length > 0 && !timeToRefetch(state.cachedAt)) {
      return state.units;
    }

    const units = await ServusApi.getUnits(propertyId);
    dispatch({ type: "units/unitsLoaded", payload: { propertyId, units } });
    return units;
  }
);

const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    unitsLoaded(state, action) {
      const { propertyId, units } = action.payload;
      state.unitsByPropertyId[propertyId] = units;
      state.cachedAtByPropertyId[propertyId] = Date.now();
    },
  },
  extraReducers: {
    [fetchUnits.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUnits.fulfilled]: (state) => {
      state.status = "idle";
    },
    [fetchUnits.rejected]: (state) => {
      state.status = "error";
    },
  },
});

export const { unitsLoaded } = unitsSlice.actions;

export default unitsSlice.reducer;
