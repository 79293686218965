import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getInspections } from "../../utils/local_cache";

const submittedInspectionsAdapter = createEntityAdapter({
  selectId: (inspection) => inspection.uuid,
  sortComparer: (a, b) => b.submitted_at.localeCompare(a.submitted_at), // Reverse sort
});

const initialState = submittedInspectionsAdapter.getInitialState({
  status: "idle",
});

export const fetchInspections = createAsyncThunk(
  "submittedInspections/fetchInspections",
  async ({ db, user }) => {
    const inspections = await getInspections(db, "submitted", user);
    return inspections;
  }
);

const submittedInspectionsSlice = createSlice({
  name: "submittedInspections",
  initialState,
  reducers: {
    inspectionAdded: submittedInspectionsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInspections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspections.fulfilled, (state, action) => {
        submittedInspectionsAdapter.setAll(state, action.payload);
        state.status = "idle";
      })
      .addCase(fetchInspections.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const { inspectionAdded } = submittedInspectionsSlice.actions;

export default submittedInspectionsSlice.reducer;

export const {
  selectAll: selectSubmittedInspections,
  selectById: selectSubmittedInspectionById,
} = submittedInspectionsAdapter.getSelectors(
  (state) => state.submittedInspections
);
