import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IonToast } from "@ionic/react";

const AppUpdate = () => {
  const [snoozing, setSnoozing] = useState(false);
  const updateAvailable = useSelector((state) => state.app.updateAvailable);
  const swReg = useSelector((state) => state.app.serviceWorkerRegistration);

  const updateApp = () => {
    const registrationWaiting = swReg && swReg.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const hideNotification = () => {
    setSnoozing(true);
    const snoozeTime = 5; // expressed in minutes
    setTimeout(() => {
      setSnoozing(false);
    }, snoozeTime * 60 * 1000);
  };

  return (
    <IonToast
      isOpen={updateAvailable && !snoozing}
      message="A new version of inspections is available!"
      position="top"
      buttons={[
        {
          text: "Update",
          handler: updateApp,
        },
        {
          text: "Later",
          handler: hideNotification,
        },
      ]}
    />
  );
};

export default AppUpdate;
