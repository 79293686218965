import { Remarkable } from "remarkable";

const renderMarkdownToHTML = (markdown) => {
  const remarkable = new Remarkable({
    html: false,
    breaks: true,
    typographer: true,
  });
  const renderedHTML = remarkable.render(markdown);
  return { __html: renderedHTML };
};

export default renderMarkdownToHTML;
