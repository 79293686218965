import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import SignatureCanvas from "react-signature-canvas";
import PromptWrapper from "./PromptWrapper";
import "./Signature.css";
import { saveResponse } from "../localInspectionSlice";
import renderMarkdownToHTML from "../../../utils/renderMarkdownToHTML";

const DEFAULT_PAD_WIDTH = 300;
const DEFAULT_PAD_HEIGHT = Math.round(DEFAULT_PAD_WIDTH / 2, 0);

const Signature = ({ prompt, viewOnly = false }) => {
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();
  const signatureBounds = useRef();
  const signaturePad = useRef();
  const response = prompt.response;

  const signatureDataUrl = response.value;

  const [modalOpen, setModalOpen] = useState(false);
  const hasSignature = Boolean(signatureDataUrl);
  const [hasDrawn, setHasDrawn] = useState(hasSignature);

  const prepareCanvas = () => {
    if (signatureBounds.current && signaturePad.current) {
      const canvas = signaturePad.current.getCanvas();
      const width = signatureBounds.current?.clientWidth || DEFAULT_PAD_WIDTH;
      const height = Math.round(width / 2, 0);
      canvas.setAttribute("width", width);
      canvas.setAttribute("height", height);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const clearSignature = () => {
    signaturePad.current.clear();
    dispatch(
      saveResponse({ path: prompt.path, type: "signature", responseData: {} })
    );
    setHasDrawn(false);
  };

  const clearSignatureWithConfirm = () => {
    presentAlert({
      header: `Remove ${prompt.title}?`,
      message:
        "This signature will be removed and will need to be captured again.",
      buttons: ["Keep", { text: "Remove", handler: clearSignature }],
    });
  };

  const saveSignature = () => {
    const responseData = {
      value: signaturePad.current.getTrimmedCanvas().toDataURL("image/png"),
      meta: { timestamp: new Date().getTime() },
    };
    dispatch(
      saveResponse({ path: prompt.path, type: "signature", responseData })
    );
    setModalOpen(false);
  };

  const menuItems =
    hasSignature && !viewOnly
      ? [{ title: "Remove Signature...", onClick: clearSignatureWithConfirm }]
      : [];

  return (
    <PromptWrapper
      type="signature"
      title={prompt.title}
      description={prompt.description}
      optional={prompt.optional}
      menuItems={menuItems}
    >
      {hasSignature && (
        <>
          <IonItem lines="none">
            <IonLabel>
              <img src={signatureDataUrl} alt="signature" />
            </IonLabel>
          </IonItem>
          {response.meta?.timestamp && (
            <IonItem lines="none">
              <IonNote>
                <div className="description">
                  Signed{" "}
                  {new Date(response.meta.timestamp).toLocaleString("en-US")}
                </div>
              </IonNote>
            </IonItem>
          )}
        </>
      )}
      {!hasSignature && !viewOnly && (
        <IonItem lines="none">
          <IonLabel>
            <IonButton
              size="large"
              expand="block"
              fill="outline"
              color="medium"
              onClick={openModal}
            >
              Add Signature
            </IonButton>
          </IonLabel>
        </IonItem>
      )}
      <IonModal
        isOpen={modalOpen}
        keepContentsMounted
        onDidPresent={prepareCanvas}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setModalOpen(false)}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>{prompt.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding-top">
          <IonList>
            {!!prompt.description && (
              <IonItem lines="none">
                <IonNote>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={renderMarkdownToHTML(
                      prompt.description
                    )}
                  />
                </IonNote>
              </IonItem>
            )}
            <IonItem lines="none">
              <div className="signature-item">
                <div className="stacked-label">Sign Below</div>
                <div className="signature-bounds" ref={signatureBounds}>
                  <SignatureCanvas
                    canvasProps={{
                      width: DEFAULT_PAD_WIDTH,
                      height: DEFAULT_PAD_HEIGHT,
                      className: "signature-canvas",
                    }}
                    onBegin={() => setHasDrawn(true)}
                    penColor="black"
                    ref={signaturePad}
                  />
                </div>
              </div>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <IonButton
                  size="large"
                  disabled={!hasDrawn}
                  expand="block"
                  onClick={saveSignature}
                >
                  Save Signature
                </IonButton>
                <IonButton
                  size="small"
                  expand="block"
                  fill="outline"
                  color="medium"
                  onClick={clearSignature}
                  className="signature-clear-button"
                >
                  Clear Signature
                </IonButton>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </PromptWrapper>
  );
};

export default Signature;
