import React, { useEffect, useState } from "react";
import { IonImg, IonSkeletonText } from "@ionic/react";
import { JSX } from "@ionic/core";
import { remoteUrlFromKey } from "../features/photos/photoUtilities";
import useDatabase from "../hooks/useDatabase";

export interface IPhoto {
  fileName?: string;
  dataUrl?: string | null; // Older photos had the dataUrl embedded in the data structure
  uuid?: string | null; // Newer photos have a uuid which is a key in the database storage
}

interface IProps extends Omit<JSX.IonImg, "src"> {
  photo: IPhoto;
}

const Photo = ({ photo, ...rest }: IProps) => {
  const db = useDatabase();
  const [resolvedSrc, setResolvedSrc] = useState("");

  const { dataUrl = null, uuid = null } = photo;

  useEffect(() => {
    let runEffect = true;

    const resolveSrc = async () => {
      if (dataUrl) {
        setResolvedSrc(dataUrl);
      }

      if (uuid) {
        if (uuid.startsWith("photo.")) {
          const url = await remoteUrlFromKey(db, uuid);
          if (url) {
            setResolvedSrc(url);
          } else {
            setResolvedSrc(await db.get(uuid));
          }
        }
        if (uuid.startsWith("photometa.")) {
          setResolvedSrc(await remoteUrlFromKey(db, uuid));
        }
      }
    };

    if (runEffect) {
      resolveSrc();
    }

    return () => {
      runEffect = false;
    };
  }, [dataUrl, uuid]);

  if (!resolvedSrc) {
    return <IonSkeletonText animated />;
  }

  return <IonImg src={resolvedSrc} {...rest} />;
};

export default Photo;
