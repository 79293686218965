import pFilter from "p-filter";
import { datadogLogs } from "@datadog/browser-logs";
import { servusApi } from "../../utils/servus_api_rtk";

/**
 * Returns an array of database keys for photos that need to be uploaded to the server.
 *
 * @async
 * @returns {Promise<string[]>}
 */
export const pendingPhotoUploadKeys = async (db) => {
  const allKeys = await db.keys();
  const photoKeys = allKeys.filter((key) => key.startsWith("photo."));
  const photoMetaKeys = allKeys.filter((key) => key.startsWith("photometa."));

  const withoutAssociatedMetaUrl = async (photoUuid) => {
    const metaKey = computeMetaKey(photoUuid);

    if (!photoMetaKeys.includes(metaKey)) {
      return true;
    }

    const metadata = (await db.get(metaKey)) || {};
    return !metadata.url;
  };

  return (
    await pFilter(photoKeys, withoutAssociatedMetaUrl, { concurrency: 1 })
  ).sort(reverseSortUuids);
};

export const uploadPhotoFromDatabase = async (db, photoKey, dispatch) => {
  const dataUrl = await db.get(photoKey);
  if (dataUrl) {
    const endpoint = servusApi.endpoints.postInspectionPhoto;
    const photoParams = { photo_uuid: photoKey, photo_data_uri: dataUrl };
    const t0 = performance.now();
    const result = await dispatch(endpoint.initiate(photoParams));
    const t1 = performance.now();
    datadogLogs.logger.info("[PhotoUploader] uploadPhotoFromDatabase", {
      meta: {
        photoKey,
        photoSize: dataUrl.length,
        success: !result?.error,
        elapsedMs: t1 - t0,
      },
    });

    if (result?.error) {
      throw new Error(result.error.error);
    }

    const url = result?.data?.inspection_photo?.url;
    if (url && String(url).startsWith("https://")) {
      const metaKey = computeMetaKey(photoKey);
      const metaData = (await db.get(metaKey)) || {};
      await db.set(metaKey, { ...metaData, url });
      await db.remove(photoKey);
    }
  }

  return true;
};

export const computeMetaKey = (fullKey) =>
  `photometa.${extractUuidPart(fullKey)}`;
export const computePhotoKey = (fullKey) => `photo.${extractUuidPart(fullKey)}`;

export const remoteUrlFromKey = async (db, key) => {
  const metakey = key.startsWith("photometa.") ? key : computeMetaKey(key);
  const metadata = (await db.get(metakey)) || {};
  return metadata.url;
};

const extractUuidPart = (dotSeparatedUuid) => {
  const dotSeparatedUuidAsString = String(dotSeparatedUuid);

  if (dotSeparatedUuidAsString.includes(".")) {
    return dotSeparatedUuidAsString.split(".")[1];
  }

  return dotSeparatedUuidAsString;
};

const reverseSortUuids = (a, b) =>
  extractUuidPart(b).localeCompare(extractUuidPart(a));
