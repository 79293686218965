import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ServusApi, { cacheDurationMs } from "../../utils/servus_api";

const initialState = {
  propertyCount: 0,
  properties: [],
  cachedAt: 0,
  status: "idle",
};

const timeToRefetch = (cachedAt) => Date.now() - cachedAt > cacheDurationMs;

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async (_arg, { dispatch, getState }) => {
    const state = getState().properties;
    if (state.propertyCount > 0 && !timeToRefetch(state.cachedAt)) {
      return state.properties;
    }

    const properties = await ServusApi.getProperties();
    dispatch({ type: "properties/propertiesLoaded", payload: properties });
    if (properties.length === 1) {
      // TODO: Should this be a subscribe?
      dispatch({
        type: "newInspection/propertySelected",
        payload: properties[0],
      });
    }
    return properties;
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    propertiesLoaded(state, action) {
      const properties = action.payload;
      const propertyCount = properties.length;

      state.propertyCount = propertyCount;
      state.properties = properties;
      state.cachedAt = Date.now();
    },
  },
  extraReducers: {
    [fetchProperties.pending]: (state) => {
      state.status = "loading";
    },
    [fetchProperties.fulfilled]: (state) => {
      state.status = "idle";
    },
    [fetchProperties.rejected]: (state) => {
      state.status = "error";
    },
  },
});

export const { propertiesLoaded } = propertiesSlice.actions;

export default propertiesSlice.reducer;
