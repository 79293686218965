import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonCheckbox,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonSearchbar,
} from "@ionic/react";
import { displayErrorMessage } from "../appSlice";
import { fetchUnits } from "./unitsSlice";
import {
  unitToggled,
  setUnitsSelected,
} from "../inspections/newInspectionSlice";
import "./UnitSelector.css";

const UnitSelector = ({ propertyId, scope }) => {
  const dispatch = useDispatch();
  const unitsStatus = useSelector((state) => state.units.status);
  const allUnitsForProperty = useSelector(
    (state) => state.units.unitsByPropertyId[propertyId] || []
  );
  const selectedUnits = useSelector(
    (state) => state.newInspection.selectedUnits
  );
  const [searchText, setSearchText] = useState("");
  const selectedTemplate = useSelector(
    (state) => state.newInspection.selectedTemplate
  );

  const filterUnits = (units) => {
    let localUnits = units;

    if (scope === "unit") {
      localUnits = localUnits.filter((unit) => unit.type === "Unit");
    } else if (scope === "location") {
      const { location_type: locationType } = selectedTemplate;

      // If location_type is none/null, select all units, otherwise filter by type
      const falsyLocations = [null, "none"];

      localUnits = falsyLocations.includes(locationType)
        ? localUnits.filter((unit) => unit.type === "Location")
        : localUnits.filter(
            (unit) =>
              unit.type === "Location" && unit.location_type === locationType
          );
    }

    if (searchText.length > 0) {
      localUnits = localUnits.filter((unit) =>
        unit.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return localUnits;
  };

  const collateUnitsByBuilding = (units) => {
    const reduceByBuilding = (map, unit) => {
      const buildingName = `Building ${unit.building}`;
      if (!map.has(buildingName)) {
        map.set(buildingName, []);
      }
      const unitsForBuilding = [...map.get(buildingName), unit];
      map.set(buildingName, unitsForBuilding);
      return map;
    };
    return units.reduce(reduceByBuilding, new Map());
  };

  const filteredUnits = filterUnits(allUnitsForProperty);
  const filteredUnitsByBuilding = collateUnitsByBuilding(filteredUnits);

  const selectAll = (e) => {
    dispatch(setUnitsSelected(filteredUnits));
    e.preventDefault();
  };

  const selectNone = (e) => {
    dispatch(setUnitsSelected([]));
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(fetchUnits(propertyId)).then(
      ({ error, meta: { requestStatus } }) => {
        if (requestStatus === "rejected") {
          dispatch(displayErrorMessage(error.message));
        }
      }
    );
  }, []);

  if (unitsStatus === "loading") {
    return <IonLoading isOpen />;
  }

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>Step 3: Choose Unit or Units</IonLabel>
      </IonListHeader>
      <IonSearchbar
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value)}
        placeholder="Search Units"
      />
      {filteredUnits.length === 0 && (
        <p className="none-found">No Units Found</p>
      )}
      {filteredUnits.length > 0 && (
        <>
          <div className="all-none-selector">
            Select{" "}
            <a href="#all" onClick={selectAll}>
              All
            </a>{" "}
            |{" "}
            <a href="#none" onClick={selectNone}>
              None
            </a>
          </div>
          {[...filteredUnitsByBuilding.keys()].map((buildingName) => (
            <IonItemGroup key={buildingName}>
              <IonItemDivider sticky color="light">
                <IonLabel>{buildingName}</IonLabel>
              </IonItemDivider>
              {filteredUnitsByBuilding.get(buildingName).map((unit, index) => {
                const isLast =
                  index + 1 ===
                  filteredUnitsByBuilding.get(buildingName).length;
                const checked = !!selectedUnits.find((u) => u.id === unit.id);
                return (
                  <IonItem key={unit.id} lines={isLast ? "none" : "inset"}>
                    <IonCheckbox
                      checked={checked}
                      slot="end"
                      mode="md"
                      onClick={() => dispatch(unitToggled(unit))}
                    />
                    <IonLabel>
                      <h2>{unit.name}</h2>
                      <p>{`Building ${unit.building}`}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonItemGroup>
          ))}
        </>
      )}
    </IonList>
  );
};

export default UnitSelector;
