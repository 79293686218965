import React from "react";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import {
  bagOutline,
  homeOutline,
  listOutline,
  personOutline,
} from "ionicons/icons";
import PresentingElementContext from "../contexts/PresentingElementContext";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import Drafts from "./Drafts";
import Home from "./Home";
import Inspection from "../components/Inspection";
import Inspections from "./Inspections";
import NewInspectionPage from "../features/inspections/NewInspectionPage";
import Profile from "./Profile";

const Main = () => {
  const routerRef = React.createRef();
  const location = useLocation();

  const shouldHideTabBar = () => {
    return location.pathname.match(/^\/main\/inspection\//);
  };

  const tabBarClassName = () => {
    if (shouldHideTabBar()) {
      return "ion-hide";
    }

    return "ion-show";
  };

  return (
    <IonTabs>
      <IonRouterOutlet ref={routerRef}>
        <PresentingElementContext.Provider value={routerRef}>
          <AuthenticatedRoute exact path="/main/home" component={Home} />
          <AuthenticatedRoute exact path="/main/drafts" component={Drafts} />
          <AuthenticatedRoute
            exact
            path="/main/inspections/new"
            component={NewInspectionPage}
          />
          <AuthenticatedRoute
            exact
            path="/main/inspection/:inspectionId"
            component={Inspection}
          />
          <AuthenticatedRoute
            exact
            path="/main/inspections/"
            component={Inspections}
          />
          <AuthenticatedRoute exact path="/main/profile" component={Profile} />
        </PresentingElementContext.Provider>
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className={tabBarClassName()}>
        <IonTabButton tab="home" href="/main/home">
          <IonIcon icon={homeOutline} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="drafts" href="/main/drafts">
          <IonIcon icon={bagOutline} />
          <IonLabel>Drafts</IonLabel>
        </IonTabButton>
        <IonTabButton tab="inspections" href="/main/inspections">
          <IonIcon icon={listOutline} />
          <IonLabel>Inspections</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/main/profile">
          <IonIcon icon={personOutline} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Main;
