export const displayAddress = (property) => {
  if (!property) {
    return "";
  }

  const isPresent = (value) => !!value;
  const addrCityState = [property.address, property.city, property.state]
    .filter(isPresent)
    .join(", ");
  return [addrCityState, property.zip_code].filter(isPresent).join(" ");
};
