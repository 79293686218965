import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import renderMarkdownToHTML from "../../utils/renderMarkdownToHTML";

const TemplateListItem = ({ template, onClick }) => (
  <IonItem button={!!onClick} onClick={onClick}>
    <IonLabel>
      <h2>{template.title}</h2>
      <div
        className="description"
        dangerouslySetInnerHTML={renderMarkdownToHTML(template.description)}
      />
    </IonLabel>
  </IonItem>
);

export default TemplateListItem;
