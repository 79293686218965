import React from "react";
import { createRoot } from "react-dom/client";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store";

const RELEASE_ENV = process.env.REACT_APP_RELEASE_ENV || process.env.NODE_ENV; // [^1]

// Bugsnag _requires_ a key or the page will not load, so provde a real default below.
// TODO: remove this when we cut over to Datadog for all errors. Datadog just warns without a key.
const BUGSNAG_API_KEY =
  process.env.REACT_APP_BUGSNAG_API_KEY || "345cabb8c974640378f75f9ca1a1c538";

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_GIT_SHA,
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
  releaseStage: RELEASE_ENV,
});

const DATADOG_SITE = "datadoghq.com";
const DATADOG_SERVICE = "servus-inspections";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_LOGS_CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: DATADOG_SERVICE,
  env: RELEASE_ENV,
  version: process.env.REACT_APP_GIT_SHA,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  telemetrySampleRate: 0,
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: DATADOG_SERVICE,
  env: RELEASE_ENV,
  version: process.env.REACT_APP_GIT_SHA || "dev",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
});
datadogRum.startSessionReplayRecording();

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

// This application uses a service worker to work offline and load faster.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (reg) =>
    store.dispatch({ type: "app/updateAvailable", payload: reg }),
});

// Foonotes
// [^1]: `NODE_ENV` is only ever 'production' or 'development', so
//       if we want to report something else (e.g. 'staging') to
//       Datadog, we can set `REACT_APP_RELEASE_ENV`.
