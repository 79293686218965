import { registerPromptType } from "../features/inspections/Prompt";
import Group from "../features/inspections/prompts/Group";
import Punchlist from "../features/inspections/prompts/Punchlist";
import Radio from "../features/inspections/prompts/Radio";
import Select from "../features/inspections/prompts/Select";
import Signature from "../features/inspections/prompts/Signature";
import Textarea from "../features/inspections/prompts/Textarea";
import Updown from "../features/inspections/prompts/Updown";

export const registerPrompts = () => {
  registerPromptType("group", Group);
  registerPromptType("radio", Radio);
  registerPromptType("punchlist", Punchlist);
  registerPromptType("picklist", Select);
  registerPromptType("signature", Signature);
  registerPromptType("textarea", Textarea);
  registerPromptType("updown", Updown);
};
