import Bugsnag from "@bugsnag/js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../utils/api";

const userKey = "user";

const initialState = {
  user: {},
  status: "idle",
};

const extractMessageFromApiError = (error) => {
  let message = "";

  if (error.response) {
    message = error.response.data.message;
  } else if (error.request) {
    message = "Network error - please try again";
  } else {
    message = error.message;
  }

  return message;
};

export const signin = createAsyncThunk(
  "user/signin",
  async ({ db, email, password, onSuccess, onError }, { dispatch }) => {
    await api
      .post(
        "/api/sessions",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => response.data.user)
      .then((user) => {
        dispatch({ type: "user/signedIn", payload: user });
        return user;
      })
      .then((user) => {
        db.set(userKey, user);
        Bugsnag.setUser(user.id, user.email, user.name);
      })
      .then(() => onSuccess())
      .catch((error) => onError(extractMessageFromApiError(error)));
  }
);

export const signout = createAsyncThunk(
  "user/signout",
  async ({ db }, { dispatch }) => {
    db.remove(userKey);
    axios.defaults.headers.common.AUTH_EMAIL = null;
    axios.defaults.headers.common.AUTH_TOKEN = null;
    dispatch({ type: "user/signedOut" });
  }
);

export const reauthenticate = createAsyncThunk(
  "user/reauthenticate",
  async ({ db }, { dispatch }) => {
    await db
      .get(userKey)
      .then((user) => {
        dispatch({ type: "user/signedIn", payload: user });
        Bugsnag.setUser(user.id, user.email, user.name);
      })
      .catch(() => dispatch({ type: "user/signout" }));
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signedIn(state, action) {
      state.user = action.payload;
    },
    signedOut(state) {
      state.user = {};
    },
  },
  extraReducers: {
    [signin.pending]: (state) => {
      state.status = "loading";
    },
    [signin.fulfilled]: (state) => {
      state.status = "idle";
    },
    [signin.rejected]: (state) => {
      state.status = "idle";
    },
    [reauthenticate.pending]: (state) => {
      state.status = "loading";
    },
    [reauthenticate.fulfilled]: (state) => {
      state.status = "idle";
    },
    [reauthenticate.rejected]: (state) => {
      state.status = "idle";
    },
  },
});

export default userSlice.reducer;
