import React, { useEffect } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import useLoggedInRedirection from "../hooks/useLoggedInRedirection";

/**
 *
 * Render a component if we are NOT logged in, otherwise redirect to the default path
 *
 */
const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const match = useRouteMatch(rest.path);
  const isAuthenticated = useSelector(
    (state) =>
      state.user.user &&
      (state.user.user.authentication_token || state.user.user.api_key)
  );
  const handleLoggedInRedirection = useLoggedInRedirection();

  useEffect(() => {
    if (match && isAuthenticated) {
      handleLoggedInRedirection();
    }
  }, [JSON.stringify(match), isAuthenticated]);

  return <Route {...rest}>{!isAuthenticated && <Component />}</Route>;
};

export default UnauthenticatedRoute;
