import React from "react";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const HelpPage = ({ children }) => (
  <IonPage className="help-page">
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/main/home" />
        </IonButtons>
        <IonTitle>Help</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent fullscreen="true">
      <div className="ion-padding">{children}</div>
    </IonContent>
  </IonPage>
);

export default HelpPage;
