import produce from "immer";
import pMap from "p-map";
import InspectionForm from "./InspectionForm.model";
import InspectionPrompt from "./InspectionPrompt.model";
import InspectionResponse from "./InspectionResponse.model";
import InspectionSection from "./InspectionSection.model";
import InspectionStats from "./InspectionStats";
import { hasOwnProperty } from "../utils/helpers";

export const InspectionSchemaVersion2 = "2";

export default class Inspection {
  #promptRegistry = {};

  #levelOnePrompts = [];

  promptCount = 0;

  answerCount = 0;

  requiredPromptCount = 0;

  requiredAnswerCount = 0;

  constructor(rawData) {
    this.rawData = rawData;
    this.form = new InspectionForm(rawData?.template?.form || {});
    this.responses = rawData?.responses || {};

    // The prompt tree is only those prompts that should be displayed
    this.prompts = this.#computePromptTree(this.form.prompts());
    this.topLevelPrompts = this.prompts.filter((prompt) => !prompt.sectionUuid);
    this.sections = this.#computeSections();

    Object.assign(this, this.#computeStats());
  }

  section(sectionUuid) {
    return this.sections.find((section) => section.uuid === sectionUuid);
  }

  get percentComplete() {
    return Math.floor(
      (this.requiredAnswerCount / this.requiredPromptCount) * 100
    );
  }

  get complete() {
    return this.requiredAnswerCount === this.requiredPromptCount;
  }

  findPrompt(path) {
    return this.#promptRegistry[path];
  }

  #computePromptRegistry(prompts) {
    const levelPrompts = [];

    prompts.forEach((prompt) => {
      const responseData = this.responseFor(prompt.path) || {};
      prompt.response = new InspectionResponse(responseData);
      const subprompts =
        prompt.type === "group" ? prompt.repeaters : prompt.prompts;
      const registryPrompt = new InspectionPrompt({
        ...prompt,
        prompts: this.#computePromptRegistry(subprompts),
      });

      // Add this prompt to registry
      this.#promptRegistry[registryPrompt.path] = registryPrompt;
      levelPrompts.push(registryPrompt);
    });

    return levelPrompts;
  }

  #setPromptVisibility(prompts) {
    return prompts
      .map((prompt) => this.findPrompt(prompt.path))
      .filter((prompt) => Boolean(prompt))
      .map(
        (prompt) =>
          new InspectionPrompt({
            ...prompt,
            shouldDisplay: this.#shouldDisplay(prompt),
            prompts: this.#setPromptVisibility(prompt.prompts || []),
          })
      );
  }

  #computePromptTree(prompts) {
    // First, fill the entire prompt registry
    const levelOnePrompts = this.#computePromptRegistry(prompts);

    // Store the "level one prompts" for later use
    this.#levelOnePrompts = levelOnePrompts;

    // Finally, set the visibility of conditional prompts
    return this.#setPromptVisibility(levelOnePrompts);
  }

  #findClosestPrompt(sourcePrompt, targetUuid) {
    const siblings = this.#siblingsOf(sourcePrompt);
    const parent = this.#parentOf(sourcePrompt);
    const target = siblings.find((sibling) => sibling.uuid === targetUuid);

    if (target) {
      return target;
    }

    if (parent) {
      return this.#findClosestPrompt(parent, targetUuid);
    }

    return null;
  }

  #siblingsOf(prompt) {
    const parent = this.#parentOf(prompt);
    const potentialSiblings = parent ? parent.prompts : this.#levelOnePrompts;

    return (potentialSiblings || []).filter(
      (candidate) => candidate.uuid !== prompt.uuid
    );
  }

  #parentOf(prompt) {
    if (prompt?.parentPath) {
      return this.findPrompt(prompt.parentPath);
    }

    return null;
  }

  #shouldDisplay(prompt) {
    if (!prompt.isConditional) {
      return true;
    }

    const dependsOnPrompt = this.#findClosestPrompt(
      prompt,
      prompt.conditionalUuid
    );
    const response = dependsOnPrompt?.response?.value;
    const condition = prompt.conditionalValue;

    let cond = [];
    let resp = [];

    if (!Array.isArray(condition)) {
      cond.push(condition);
    } else {
      cond = condition;
    }

    if (!Array.isArray(response)) {
      resp.push(response);
    } else {
      resp = response;
    }

    // If any response appears in the condition set, return true
    return resp.some((r) => cond.includes(r));
  }

  #computeSections() {
    return this.form.sections().map(
      (section) =>
        new InspectionSection({
          ...section,
          prompts: this.#sectionPrompts(section.uuid),
          response: this.responseFor(section.uuid),
        })
    );
  }

  #sectionPrompts(sectionUuid) {
    return this.prompts.filter((prompt) => prompt.sectionUuid === sectionUuid);
  }

  massagedData() {
    const updatedData = produce(this.rawData, (draft) => {
      /* eslint-disable no-param-reassign */

      // 1. Mark all sections that default to "off" and have no response yet as "skipped".
      // (These started "off" and were never toggled "on" or "off", so have no response.)
      //
      // 2. Mark all prompts within the sections that are "off" as being in a skipped section
      this.form.sections().forEach((section) => {
        const sectionResponse = this.responseFor(section.uuid);
        if (
          section.initialState === "off" &&
          !hasOwnProperty(sectionResponse, "skipped")
        ) {
          draft.responses[section.uuid] = { skipped: true };
        }

        if (draft.responses[section.uuid]?.skipped) {
          section.prompts.forEach((prompt) => {
            draft.responses[prompt.uuid] = { meta: { section_skipped: true } };
          });
        } else {
          section.prompts.forEach((prompt) => {
            const promptResponse = draft.responses[prompt.uuid];
            if (
              promptResponse &&
              hasOwnProperty(promptResponse.meta, "section_skipped")
            ) {
              delete draft.responses[prompt.uuid].meta.section_skipped;
            }
          });
        }
      });
      /* eslint-enable no-param-reassign */
    });

    return updatedData;
  }

  responseFor(path) {
    return this.responses[path];
  }

  #photoUuids() {
    const photoUuids = [];
    const responses = this.rawData.responses || {};
    const extractUuids = (photos) =>
      photos.map((photo) => photo?.uuid).filter((uuid) => !!uuid);
    Object.values(responses).forEach((response) => {
      const photos = response?.photos;
      const items = response?.items;

      if (Array.isArray(photos)) {
        photoUuids.push(...extractUuids(photos));
      }

      if (Array.isArray(items)) {
        const itemPhotos = items.flatMap((item) => item?.photos);
        photoUuids.push(...extractUuids(itemPhotos));
      }
    });

    return photoUuids;
  }

  static inlinePhotos(photos, photoCache) {
    return photos.map((photo) => {
      if (photo.uuid && !photo.dataUrl && photoCache[photo.uuid]) {
        return { ...photo, dataUrl: photoCache[photo.uuid] };
      }

      return photo;
    });
  }

  #computeStats() {
    return new InspectionStats(
      this.prompts,
      this.#disabledSectionUuids
    ).compute();
  }

  get #disabledSectionUuids() {
    return this.sections
      .filter((section) => section.disabled)
      .map((section) => section.uuid);
  }
}
