import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v1 as uuidv1 } from "uuid";
import InspectionForm from "../../models/InspectionForm.model";
import * as Cache from "../../utils/local_cache";
import ServusApi from "../../utils/servus_api";

const STATUSES = {
  idle: "idle",
  starting: "starting",
  preparing: "preparing",
  ready: "ready",
};

const initialState = {
  status: STATUSES.idle,
  selectedProperty: null,
  selectedTemplate: null,
  selectedUnits: [],
};

export const generateDraftInspections = createAsyncThunk(
  "newInspection/generateDraftInspections",
  async ({ db, user }, { dispatch, getState }) => {
    // Note: the spread syntax makes a copy so we don't sort-in-place on state
    const selectedUnits = [...getState().newInspection.selectedUnits];
    selectedUnits.sort((u1, u2) => u1.name.localeCompare(u2.name));

    const { selectedTemplate, selectedProperty } = getState().newInspection;

    const inspections = selectedUnits.map((unit) => ({
      uuid: uuidv1(),
      started_at: new Date().toJSON(),
      status: "draft",
      percent_complete: 0,
      user,
      unit,
      template: selectedTemplate,
      property: selectedProperty,
      responses: {},
      image: {},
    }));

    const promises = [];

    // Fetch unit image and add to inspection
    inspections.forEach((inspection) => {
      promises.push(ServusApi.getUnitImage(inspection.unit.id, "floorplan"));
    });

    await Promise.all(promises)
      .then((result) => {
        result.forEach((image) => {
          // Associate image to inspection by unit ID
          inspections.forEach((inspection, index) => {
            if (inspection.unit.id === image.unit_id) {
              inspections[index].image = image;
            }
          });
        });
      })
      .catch(() => {});

    await Cache.saveInspections(db, inspections);
    await dispatch({
      type: "inspections/inspectionsAdded",
      payload: inspections,
    });
    return inspections;
  }
);

export const generateDraftInspectionFromRequirement = createAsyncThunk(
  "newInspection/generateDraftInspectionFromRequirement",
  async ({ db, user, property, template, unit }, { dispatch }) => {
    const inspection = {
      uuid: uuidv1(),
      started_at: new Date().toJSON(),
      status: "draft",
      percent_complete: 0,
      user,
      unit,
      template,
      property,
      responses: {},
      image: {},
    };

    const promptHasChooseLocation = (prompt) =>
      (prompt.data || {}).choose_location;
    const prompts = new InspectionForm(template.form).prompts();

    if (prompts.some(promptHasChooseLocation)) {
      try {
        inspection.image = await ServusApi.getUnitImage(unit.id, "floorplan");
      } catch (e) {
        // ignore
      }
    }

    await Cache.saveInspection(db, inspection);
    await dispatch({
      type: "inspections/inspectionAdded",
      payload: inspection,
    });
    return inspection;
  }
);

const newInspectionSlice = createSlice({
  name: "newInspection",
  initialState,
  reducers: {
    inspectionStarted(state) {
      state.status = STATUSES.starting;
      state.selectedUnits = [];
    },
    inspectionDismissed(state) {
      state.status = STATUSES.idle;
      state.selectedUnits = [];
    },
    propertySelected(state, action) {
      if (state.selectedProperty !== action.payload) {
        state.selectedProperty = action.payload;
        state.selectedTemplate = null;
        state.selectedUnits = [];
      }
    },
    propertyUnselected(state) {
      state.selectedProperty = null;
      state.selectedTemplate = null;
      state.selectedUnits = [];
    },
    templateSelected(state, action) {
      state.selectedTemplate = action.payload;
    },
    templateUnselected(state) {
      state.selectedTemplate = null;
      state.selectedUnits = [];
    },
    unitToggled(state, action) {
      const toggledUnit = action.payload;
      const selectedUnits = state.selectedUnits;
      const matchingUnit = selectedUnits.find((u) => u.id === toggledUnit.id);

      if (matchingUnit) {
        // Remove
        state.selectedUnits = selectedUnits.filter(
          (u) => u.id !== matchingUnit.id
        );
      } else {
        // Add
        state.selectedUnits = [...selectedUnits, toggledUnit];
      }
    },
    setUnitsSelected(state, action) {
      state.selectedUnits = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateDraftInspections.pending, (state) => {
        state.status = STATUSES.preparing;
      })
      .addCase(generateDraftInspections.fulfilled, (state) => {
        state.status = STATUSES.ready;
      })
      .addCase(generateDraftInspectionFromRequirement.pending, (state) => {
        state.status = STATUSES.preparing;
      })
      .addCase(generateDraftInspectionFromRequirement.fulfilled, (state) => {
        state.status = STATUSES.ready;
      });
  },
});

export const {
  inspectionStarted,
  inspectionDismissed,
  propertySelected,
  propertyUnselected,
  templateSelected,
  templateUnselected,
  unitToggled,
  setUnitsSelected,
} = newInspectionSlice.actions;

export default newInspectionSlice.reducer;
