import React from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const Debug = () => {
  const generateException = () => {
    throw new Error("Debug exception");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Debug</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen="true">
        <IonList inset mode="ios">
          {Object.entries(process.env).map(([key, val]) => (
            <IonItem key={key}>
              <IonLabel>
                {key}
                <p>{val}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>

        <div className="ion-padding">
          <IonButton
            size="default"
            fill="outline"
            expand="block"
            onClick={generateException}
          >
            Generate Exception
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Debug;
