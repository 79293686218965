import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import { inspectionStarted } from "./newInspectionSlice";

const NewInspectionWidget = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const startInspection = () => {
    dispatch(inspectionStarted());
    history.push("/main/inspections/new");
  };

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton onClick={startInspection}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  );
};

export default NewInspectionWidget;
