import hasOwn from "object.hasown";
import InspectionPrompt from "./InspectionPrompt.model";
import InspectionSection from "./InspectionSection.model";

export default class InspectionForm {
  constructor(form) {
    this.form = form || {};
  }

  /**
   * Returns an array of prompts defined within the form.
   *
   * When called without an argument, it returns all prompts defined by the form, whether the
   * prompts appeared under the top-level prompts key or within the sections key.
   *
   * When called with a null sectionUuid, it returns all top-level prompts, i.e. those under
   * the prompts key.
   *
   * When called with sectionUuid set to a UUID, it returns the prompts within that section.
   *
   * @param {Object} options
   * @returns InspectionPrompt[]
   */
  prompts(options = {}) {
    const data = [];

    if (hasOwn(options, "sectionUuid")) {
      if (options.sectionUuid) {
        // Specific section prompts
        data.push(...this.#sectionPromptsData(options.sectionUuid));
      } else {
        // Top-level prompts
        data.push(...this.#promptsData());
      }
    } else {
      // All prompts
      data.push(...this.#promptsData().concat(this.#sectionsPromptsData()));
    }

    return data.map((promptData) => new InspectionPrompt(promptData));
  }

  section(sectionUuid) {
    const sectionData = this.#sectionData(sectionUuid);

    if (!sectionData) {
      return null;
    }

    return new InspectionSection(sectionData);
  }

  sections() {
    return this.#sectionsData().map((data) => new InspectionSection(data));
  }

  #sectionPromptsData(sectionUuid) {
    const promptsData = this.#sectionData(sectionUuid)?.prompts || [];
    return promptsData.map((data) => ({ ...data, sectionUuid }));
  }

  #sectionsPromptsData() {
    return this.sections().flatMap((section) => {
      const promptsData = section.prompts || [];
      return promptsData.map((data) => ({
        ...data,
        sectionUuid: section.uuid,
      }));
    });
  }

  #promptsData() {
    return this.form.prompts || [];
  }

  #sectionData(sectionUuid) {
    return this.#sectionsData().find((section) => section.uuid === sectionUuid);
  }

  #sectionsData() {
    return this.form.sections || [];
  }
}
