import { v1 as uuidv1 } from "uuid";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import { IPhoto } from "../components/Photo";
import useDatabase from "./useDatabase";

export default function usePhotoGallery() {
  const db = useDatabase();
  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 70,
      width: 1024,
      height: 1024,
      direction: CameraDirection.Rear,
    });
    const fileName = `${new Date().getTime()}.jpeg`;
    const uuid = `photo.${uuidv1()}`;
    const dataUrl = await base64FromPath(cameraPhoto.webPath!);
    await db.set(uuid, dataUrl);

    const photo: IPhoto = {
      fileName,
      uuid,
    };

    return photo;
  };

  return { takePhoto };
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("method did not return a string"));
      }
    };
    reader.readAsDataURL(blob);
  });
}
