import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const prepareHeaders = (headers, { getState }) => {
  const user = getState().user.user;
  headers.set("Accept", "application/json");
  if (user) {
    if (user.api_key) {
      headers.set("API_KEY", user.api_key);
    } else {
      headers.set("AUTH_EMAIL", user.email);
      headers.set("AUTH_TOKEN", user.authentication_token);
    }
  }
  return headers;
};

export const servusApi = createApi({
  reducerPath: "servusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVUS_URL,
    prepareHeaders,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getInspectionRequirement: builder.query({
      query: ({ id, params }) => ({
        url: `api/v2/inspection_requirements/${id}`,
        params,
      }),
    }),
    getLoginMethods: builder.query({
      query: ({ email }) => ({
        url: "api/v2/login_methods",
        params: { email },
      }),
    }),
    getUserSession: builder.query({
      query: (apiKey) => ({
        url: "api/v2/session",
        headers: { API_KEY: apiKey },
      }),
    }),
    postInspectionPhoto: builder.mutation({
      query: (photoParams) => ({
        url: "api/inspection_photos",
        method: "POST",
        body: { inspection_photo: photoParams },
      }),
    }),
    postOauth2Token: builder.mutation({
      query: (params) => ({
        url: "oauth2/token",
        method: "POST",
        body: new URLSearchParams(params),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),
  }),
});

export const {
  useGetInspectionRequirementQuery,
  useLazyGetLoginMethodsQuery,
  useLazyGetUserSessionQuery,
  usePostInspectionPhotoMutation,
  usePostOauth2TokenMutation,
} = servusApi;
