export default class InspectionResponse {
  constructor(params = {}) {
    this.type = params.type;
    this.value = params.value;
    this.note = params.note;
    this.labels = params.labels || {}; // Only for old groups. Now in `value` on the repeater.
    this.rawItems = params.items;
    this.items = params.items || []; // Only for punchlists
    this.skipped = !!params.skipped; // Only for sections
    this.photos = params.photos || [];
  }

  get repeaterKeys() {
    // Since PR#X, groups store their repeater keys in value instead of `repeaterKeys`
    if (this.type === "group" && Array.isArray(this.value)) {
      return this.value;
    }

    return [];
  }

  get answered() {
    switch (this.type) {
      case "group":
        return this.repeaterKeys.length > 0;
      case "punchlist":
        return this.items.length > 0 || Array.isArray(this.rawItems);
      case "radio":
      case "picklist":
      case "signature":
      case "textarea":
        return this.#valueIsPresent;
      case "updown":
        if (this.value === false && !this.note) {
          return false;
        }

        return this.#valueIsPresent;
      default:
        return false;
    }
  }

  get #valueIsPresent() {
    switch (typeof this.value) {
      case "undefined":
        return false;
      case "object":
        if (this.value === null) {
          return false;
        }

        return Object.keys(this.value).length > 0;
      case "string":
        return this.value.length > 0;
      default:
        return true;
    }
  }
}
