import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateAvailable: false,
  serviceWorkerRegistration: null,
  isLoading: false,
  transientErrorMessage: null,
  fatalErrorMessage: null,
  toastMessage: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateAvailable(state, action) {
      state.updateAvailable = true;
      state.serviceWorkerRegistration = action.payload;
    },
    appIsLoading(state) {
      state.isLoading = true;
    },
    appDoneLoading(state) {
      state.isLoading = false;
    },
    displayErrorMessage(state, { payload }) {
      state.transientErrorMessage = payload;
    },
    clearErrorMessage(state) {
      state.transientErrorMessage = null;
    },
    displayFatalErrorMessage(state, { payload }) {
      state.fatalErrorMessage = payload;
    },
    clearFatalErrorMessage(state) {
      state.fatalErrorMessage = null;
    },
    displayToastMessage(state, { payload }) {
      state.toastMessage = payload;
    },
    clearToastMessage(state) {
      state.toastMessage = null;
    },
  },
});

export const {
  updateAvailable,
  appIsLoading,
  appDoneLoading,
  displayErrorMessage,
  clearErrorMessage,
  displayFatalErrorMessage,
  clearFatalErrorMessage,
  displayToastMessage,
  clearToastMessage,
} = appSlice.actions;

export default appSlice.reducer;
