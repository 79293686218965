import hasOwn from "object.hasown";
import InspectionResponse from "./InspectionResponse.model";

const DEFAULT_REPEATER_KEY = "0";
class InspectionPrompt {
  constructor(params = {}) {
    this.uuid = params.uuid || "";
    this.title = params.title;
    this.type = params.type;
    this.description = params.description;
    this.data = params.data || {};
    this.optional = params.optional === true;
    this.sectionUuid = params.sectionUuid || null;
    this.repeaterKey = params.repeaterKey || null;
    this.conditional = params.conditional || {};
    this.parentPath = params.parentPath || null;
    this.response = InspectionPrompt.#castResponse(params.response);
    this.prompts = this.#castSubprompts(params);
    this.shouldDisplay = params.shouldDisplay;
  }

  get path() {
    if (this.parentPath) {
      return `${this.parentPath}/${this.#myPath}`;
    }

    return this.#myPath;
  }

  get required() {
    return !this.optional;
  }

  get isConditional() {
    return hasOwn(this.conditional, "match");
  }

  get conditionalUuid() {
    return this.isConditional ? Object.keys(this.conditional.match)[0] : null;
  }

  get conditionalValue() {
    return this.isConditional ? Object.values(this.conditional.match)[0] : null;
  }

  get repeaters() {
    if (this.type !== "group") {
      return [];
    }

    return this.#requiredRepeaterKeys.map(
      (repeaterKey) =>
        new InspectionPrompt({
          ...this,
          type: "repeater",
          repeaterKey,
          parentPath: this.path,
        })
    );
  }

  // Returns the repeater keys from the response, if present, or otherwise
  // the default repeater key
  get #requiredRepeaterKeys() {
    const responseRepeaterKeys = this.response?.repeaterKeys || [];

    if (responseRepeaterKeys.length > 0) {
      return responseRepeaterKeys;
    }

    return [DEFAULT_REPEATER_KEY];
  }

  get #myPath() {
    if (this.repeaterKey) {
      return `${this.uuid}[${this.repeaterKey}]`;
    }

    return this.uuid;
  }

  #castSubprompts(params) {
    const subpromptData = params.prompts || [];

    return subpromptData.map((promptData) =>
      InspectionPrompt.castPrompt({ ...promptData, parentPath: this.path })
    );
  }

  static #castResponse(responseParams) {
    if (!responseParams) {
      return null;
    }

    if (responseParams instanceof InspectionResponse) {
      return responseParams;
    }

    return new InspectionResponse(responseParams);
  }

  static castPrompt(promptData) {
    if (promptData instanceof InspectionPrompt) {
      return promptData;
    }

    return new InspectionPrompt(promptData);
  }
}

export default InspectionPrompt;
