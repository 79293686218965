import React from "react";
import { IonBadge } from "@ionic/react";

const PercentCompleteBadge = ({ percentComplete, slot }) => {
  const value = percentComplete || 0;
  let color = "medium";
  switch (value) {
    case 0:
      color = "light";
      break;
    case 100:
      color = "success";
      break;
    default:
      color = "warning";
  }

  return (
    <IonBadge style={{ width: "50px" }} color={color} slot={slot}>
      {value}%
    </IonBadge>
  );
};

export default PercentCompleteBadge;
