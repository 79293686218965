import React, { useContext } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import PresentingElementContext from "../contexts/PresentingElementContext";

const Modal = (props) => {
  const {
    isLoading,
    title,
    dismissModal,
    footerContent,
    buttonsEnd,
    children,
    pageClassName,
    ...passthruProps
  } = props;
  const contextRef = useContext(PresentingElementContext);
  const modalRef = React.createRef();

  return (
    <IonModal
      presentingElement={contextRef.current}
      ref={modalRef}
      {...passthruProps}
    >
      <PresentingElementContext.Provider value={modalRef}>
        <IonPage className={pageClassName}>
          {isLoading && <IonLoading isOpen />}
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={dismissModal}>Done</IonButton>
              </IonButtons>
              {!!buttonsEnd && <IonButtons slot="end">{buttonsEnd}</IonButtons>}
            </IonToolbar>
          </IonHeader>
          <IonContent>{children}</IonContent>
          {footerContent && <IonFooter>{footerContent}</IonFooter>}
        </IonPage>
      </PresentingElementContext.Provider>
    </IonModal>
  );
};

export default Modal;
