import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  IonButtons,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { generateDraftInspectionFromRequirement } from "../features/inspections/newInspectionSlice";
import { useGetInspectionRequirementQuery } from "../utils/servus_api_rtk";
import { getJsonApiRelationship } from "../utils/jsonApiUtils";
import useDatabase from "../hooks/useDatabase";

const LaunchInspection = () => {
  const db = useDatabase();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const user = useSelector((state) => state.user.user);
  const isStartingInspection = useSelector(
    (state) => state.newInspection.status === "preparing"
  );

  const { data, isLoading, isError } = useGetInspectionRequirementQuery({
    id: queryParams.get("requirement_id"),
    params: { include: "property,inspection_template,target" },
  });

  const requirementAttributes = data?.data?.attributes;
  const propertyAttributes = getJsonApiRelationship(
    data,
    "property"
  )?.attributes;
  const templateAttributes = getJsonApiRelationship(
    data,
    "inspection_template"
  )?.attributes;
  const unitAttributes = getJsonApiRelationship(data, "target")?.attributes;

  const handleStart = () => {
    dispatch(
      generateDraftInspectionFromRequirement({
        db,
        user,
        property: propertyAttributes,
        template: templateAttributes,
        unit: unitAttributes,
      })
    ).then(({ meta: { requestStatus }, payload, error }) => {
      if (requestStatus === "fulfilled") {
        history.push(`/main/inspection/${payload.uuid}`);
      } else {
        console.log("error", error); // eslint-disable-line no-console
      }
    });
  };

  return (
    <IonPage className="shaded">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton href="/main/home" routerDirection="root">
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Launch Inspection</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonLoading
        isOpen={isLoading || isStartingInspection}
        message="Preparing inspection..."
      />

      <IonContent fullscreen="true">
        {!isLoading && !isError && (
          <>
            <div className="ion-padding">
              <IonNote>
                This inspection will fulfill a requirement for the{" "}
                <strong>{requirementAttributes?.project_name}</strong> project.
              </IonNote>
            </div>

            <IonList inset mode="ios" lines="inset">
              <IonItem>
                <IonLabel>
                  <h1>Property</h1>
                </IonLabel>
                <IonIcon icon={checkmarkCircle} slot="end" color="primary" />
              </IonItem>
              <IonItem>
                <IonLabel>{propertyAttributes?.name}</IonLabel>
              </IonItem>
            </IonList>

            <IonList inset mode="ios" lines="inset">
              <IonItem>
                <IonLabel>
                  <h1>Template</h1>
                </IonLabel>
                <IonIcon icon={checkmarkCircle} slot="end" color="primary" />
              </IonItem>
              <IonItem>
                <IonLabel>{templateAttributes?.title}</IonLabel>
              </IonItem>
            </IonList>

            <IonList inset mode="ios" lines="inset">
              <IonItem>
                <IonLabel>
                  <h1>Unit</h1>
                </IonLabel>
                <IonIcon icon={checkmarkCircle} slot="end" color="primary" />
              </IonItem>
              <IonItem>
                <IonLabel>
                  <div>{unitAttributes?.name}</div>
                  {unitAttributes?.building && (
                    <p>
                      Building&nbsp;
                      {unitAttributes?.building}
                    </p>
                  )}
                </IonLabel>
              </IonItem>
            </IonList>
          </>
        )}

        {isError && (
          <div className="ion-padding">
            <IonNote>
              <p>
                Sorry, you don&apos;t have access to launch this inspection.
              </p>
              <p>
                This probably means you&apos;re logged into Inspections with the
                wrong account.
              </p>
              <p>
                Check your profile page to make sure you&apos;re logged in with
                the correct account.
              </p>
            </IonNote>
          </div>
        )}
      </IonContent>

      {requirementAttributes && (
        <IonFooter>
          <IonToolbar>
            <div className="ion-padding">
              <IonButton expand="block" size="large" onClick={handleStart}>
                Start Inspection
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default LaunchInspection;
